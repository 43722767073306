import React, { Component } from 'react';
import { Grid, Button, Typography, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
import '../CSS/Settings.css';
import { deleteRecord, getDiscount, createNewDiscount, updateDiscount } from '../utils/OpenCampground_Database_Calls'
import Radio from '@mui/material/Radio';
import TextField from '@mui/material/TextField';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Dialog, DialogContent, DialogTitle, DialogActions } from '@mui/material';
import { withStyles } from '@material-ui/core/styles';

const useStyles = theme => ({
    dialogPaper: {
        padding: theme.spacing(2),
        borderRadius: '10px',
    }
});

const theme = createTheme({
    palette: {
        primary: {
            main: '#2dcd7a',
        },
    },
});

class Discounts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            discounts: [],
            discountsUpdate: false,
            discountName: '',
            duration: '0',
            discountPercent: '',
            amt: '',
            amtDay: '',
            amtWeek: '',
            amtMonth: '',
            sunday: true,
            monday: true,
            tuesday: true,
            wednesday: true,
            thursday: true,
            friday: true,
            saturday: true,
            daily: true,
            week: false,
            month: false,
            seasonal: false,
            showRemote: false,
            active: true,
            formValid: false,
            editingDiscountId: null,
            discoutnType: 'percent',
            open: false,
            popupTitle: '',
            popupText: '',
            deleteId: '',
            confirmOpen: false,
            confirmPopupTitle: 'Delete Discount',
            ConfirmPopupText: 'Please make sure that the discount that you are deleting is not associated with any of the current or future reservations.'
        };
    }

    async componentDidMount() {
        this.discounts()
    }

    handleChange = (event) => {
        if (event.target.id === "daily" || event.target.id === "week" || event.target.id === "month" || event.target.id === "seasonal" || event.target.id === "active" || event.target.id === "showRemote") {
            this.setState({
                [event.target.id]: event.target.checked
            })
        } else if (event.target.id === "sunday" || event.target.id === "monday" || event.target.id === "tuesday" || event.target.id === "wednesday" || event.target.id === "thursday" || event.target.id === "friday" || event.target.id === "saturday") {
            this.setState({
                [event.target.id]: event.target.checked
            })
        } else {
            if (event.target.id === 'amt') {
                this.setState({
                    amt: event.target.value,
                    amtDay: 0,
                    amtWeek: 0,
                    amtMonth: 0
                }, () => {
                    this.formValidation();
                });
            } else if (event.target.id === "amtDay" || event.target.id === "amtWeek" || event.target.id === "amtMonth") {
                this.setState({
                    [event.target.id]: event.target.value,
                    amt: 0
                }, () => {
                    this.formValidation();
                });
            } else {
                this.setState({
                    [event.target.id]: event.target.value
                }, () => {
                    this.formValidation();
                })
            }
        }
    }

    formValidation = () => {
        if (this.state.discoutnType === 'percent') {
            if (this.state.discountName && this.state.discountPercent) {
                this.setState({
                    formValid: true
                })
            } else if (this.state.formValid) {
                this.setState({
                    formValid: false
                })
            }
        } else {
            if ((this.state.discountName && this.state.amt) || (this.state.discountName && this.state.amtDay && this.state.amtWeek && this.state.amtMonth)) {
                this.setState({
                    formValid: true
                })
            } else if (this.state.formValid) {
                this.setState({
                    formValid: false
                })
            }
        }
    }

    deleteRecord = async (data) => {
        let deleteResponse = await deleteRecord(this.props.user, this.props.serverName, "discounts", data)
        this.setState({
            discountsUpdate: true,
            open: true,
            popupTitle: 'Delete Space',
            popupText: 'Space deleted successfully.'
        })
        this.discounts()

    }

    discounts = async () => {
        if (this.state.discountsUpdate) {
            let getDiscountData = await getDiscount(this.props.user, this.props.serverName)
            this.setState({
                discounts: getDiscountData
            })
        } else {
            if (this.state.discounts.length > 0) {
            } else {
                let getDiscountData = await getDiscount(this.props.user, this.props.serverName)
                this.setState({
                    discounts: getDiscountData
                })
            }
        }
    }

    addNew = async () => {
        let createNewDiscountResponse = await createNewDiscount(this.props.user, this.props.serverName, this.state.discountName, this.state.duration, this.state.sunday ? "1" : "0",
            this.state.monday ? "1" : "0", this.state.tuesday ? "1" : "0", this.state.wednesday ? "1" : "0", this.state.thursday ? "1" : "0",
            this.state.friday ? "1" : "0", this.state.saturday ? "1" : "0", this.state.discountPercent, this.state.daily ? "1" : "0",
            this.state.week ? "1" : "0", this.state.month ? "1" : "0", this.state.seasonal ? "1" : "0", this.state.amt,
            this.state.amtDay, this.state.amtWeek, this.state.amtMonth)
        this.setState({
            discountsUpdate: true,
            addNew: false,
            popupTitle: 'New Discount',
            popupText: 'New discount added successfully.',
            open: true
        })
        this.discounts()
    }

    startEditing = (discount) => {
        let discountType
        if (discount.discount_percent > 0) {
            discountType = 'percent'
        } else {
            discountType = 'amount'
        }
        this.setState({
            editingDiscountId: discount.id,
            discountName: discount.name,
            duration: discount.duration,
            discountPercent: discount.discount_percent,
            amt: discount.amount,
            amtDay: discount.amount_daily,
            amtWeek: discount.amount_weekly,
            amtMonth: discount.amount_monthly,
            sunday: discount.sunday ? 'Yes' : 'No',
            monday: discount.monday ? 'Yes' : 'No',
            tuesday: discount.tuesday ? 'Yes' : 'No',
            wednesday: discount.wednesday ? 'Yes' : 'No',
            thursday: discount.thursday ? 'Yes' : 'No',
            friday: discount.friday ? 'Yes' : 'No',
            saturday: discount.saturday ? 'Yes' : 'No',
            daily: discount.disc_appl_daily ? 'Yes' : 'No',
            week: discount.disc_appl_week ? 'Yes' : 'No',
            month: discount.disc_appl_month ? 'Yes' : 'No',
            seasonal: discount.disc_appl_seasonal ? 'Yes' : 'No',
            showRemote: discount.show_on_remote ? 'Yes' : 'No',
            active: discount.active ? 'Yes' : 'No',
            discountType: discountType
        });
    }

    saveEdits = async () => {
        console.log("active: ", this.state.active ? '1' : '0')
        let discountResponse = await updateDiscount(this.props.user, this.props.serverName, this.state.editingDiscountId, this.state.discountName, this.state.duration, this.state.discountPercent,
            this.state.amt, this.state.amtDay, this.state.amtWeek, this.state.amtMonth, this.state.sunday ? '1' : '0', this.state.monday ? '1' : '0',
            this.state.tuesday ? '1' : '0' ? '1' : '0', this.state.wednesday ? '1' : '0', this.state.thursday ? '1' : '0', this.state.friday ? '1' : '0', this.state.saturday ? '1' : '0',
            this.state.daily ? '1' : '0', this.state.week ? '1' : '0', this.state.month ? '1' : '0', this.state.seasonal ? '1' : '0', this.state.showRemote ? '1' : '0', this.state.active ? '1' : '0');
        this.setState({
            discountName: '',
            duration: '',
            discountPercent: '',
            amt: '',
            amtDay: '',
            amtWeek: '',
            amtMonth: '',
            sunday: true,
            monday: true,
            tuesday: true,
            wednesday: true,
            thursday: true,
            friday: true,
            saturday: true,
            daily: true,
            week: false,
            month: false,
            seasonal: false,
            showRemote: false,
            active: true,
            editingDiscountId: null,
            discountsUpdate: true,
            open: true,
            popupTitle: 'Edit Discount',
            popupText: 'Discount updated successfully.'
        });
        this.discounts();
    }

    isRadioChecked = (value) => {
        return this.state.discoutnType === value;
    }

    render() {
        const { classes } = this.props;
        return (
            <ThemeProvider theme={theme}>
                <Dialog open={this.state.confirmOpen} onClick={() => this.setState({ confirmOpen: false })} classes={{ paper: classes.dialogPaper }}>
                    <DialogTitle>{this.state.confirmPopupTitle}</DialogTitle>
                    <DialogContent>
                        {this.state.ConfirmPopupText}
                    </DialogContent>
                    <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button onClick={() => this.setState({ confirmOpen: false })} style={{ backgroundColor: '#FDECEB', color: '#DB3737', padding: '2% 10% 2% 10%', margin: '0 0 0 2%', borderRadius: '10px', marginTop: '3%' }}>
                            Cancel
                        </Button>
                        <Button onClick={() => this.deleteRecord(this.state.deleteId)} style={{ backgroundColor: '#2dcd7a', color: '#000000', padding: '2% 10% 2% 10%', margin: '0 0 0 2%', borderRadius: '10px', marginTop: '3%' }}>
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={this.state.open} onClose={() => { this.setState({ open: false }) }} classes={{ paper: classes.dialogPaper }}>
                    <DialogTitle>{this.state.popupTitle}</DialogTitle>
                    <DialogContent>
                        {this.state.popupText}
                    </DialogContent>
                    <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button onClick={() => { this.setState({ open: false }) }} style={{ backgroundColor: '#FDECEB', color: '#DB3737', padding: '2% 10% 2% 10%', margin: '0 0 0 2%', borderRadius: '10px', marginTop: '3%' }}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
                <Button className='resButton' onClick={() => { this.setState({ addNew: true }) }} style={{ backgroundColor: '#2dcd7a', color: '#000000', marginBottom: '2%', textTransform: "capitalize" }}>Add New Discount</Button>
                {this.state.addNew || this.state.editingDiscountId
                    ?
                    <Dialog open={this.state.addNew || this.state.editingDiscountId} onClose={() => { this.setState({ addNew: false, editingDiscountId: null }); }} maxWidth="sm" fullWidth>
                        <DialogContent dividers>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                                <h2 style={{ margin: "0px", marginBottom: "10px" }}>{this.state.editingDiscountId ? "Update Discount" : "Add Discount"}</h2>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '16px', marginLeft: '2%' }}>
                                    <TextField
                                        style={{ width: "50%" }}
                                        id="discountName"
                                        required
                                        label="Name"
                                        value={this.state.discountName}
                                        onChange={this.handleChange}
                                    />
                                    <TextField
                                        style={{ width: "50%" }}
                                        id="duration"
                                        label="Duration (In Days)"
                                        value={this.state.duration}
                                        onChange={this.handleChange}
                                    />

                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '16px', marginLeft: '2%' }}>
                                    <label>
                                        <Radio type='radio' name='option' value='percent' checked={this.isRadioChecked('percent')} onChange={() => {
                                            this.setState({ discoutnType: 'percent', amt: '0', amtDay: '0', amtWeek: '0', amtMonth: '0' })
                                        }} />
                                        Percent
                                    </label>
                                    <label>
                                        <Radio type='radio' name='option' value='amount' checked={this.isRadioChecked('amount')} onChange={() => {
                                            this.setState({ discoutnType: 'amount', discountPercent: '0', daily: false, week: false, month: false, seasonal: false, })
                                        }} />
                                        Amount
                                    </label>
                                </div>
                                {this.state.discoutnType === 'percent'
                                    ?
                                    <>
                                        <div style={{ display: 'flex', alignItems: 'center', gap: '16px', marginLeft: '2%' }}>
                                            <TextField
                                                style={{ width: "48.5%" }}
                                                id="discountPercent"
                                                required
                                                label="Percentage"
                                                value={this.state.discountPercent}
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                        <div style={{ display: 'grid', gridTemplateColumns: "1fr 1fr", gap: '0px 16px', marginLeft: '2%' }}>
                                            <FormControlLabel
                                                label={"Applies to Daily rate"}
                                                control={
                                                    <Checkbox
                                                        id="daily"
                                                        checked={this.state.daily == "0" || this.state.daily == "No" ? false : true}
                                                        onChange={this.handleChange}
                                                    />
                                                }
                                            />
                                            <FormControlLabel
                                                label={"Applies to Weekly rate"}
                                                control={
                                                    <Checkbox
                                                        id="week"
                                                        checked={this.state.week == "0" || this.state.week == "No" ? false : true}
                                                        onChange={this.handleChange}
                                                    />
                                                }
                                            />
                                            <FormControlLabel
                                                label={"Applies to Monthly rate"}
                                                control={
                                                    <Checkbox
                                                        id="month"
                                                        checked={this.state.month == "0" || this.state.month == "No" ? false : true}
                                                        onChange={this.handleChange}
                                                    />
                                                }
                                            />
                                            <FormControlLabel
                                                label={"Applies to Seasonal rate"}
                                                control={
                                                    <Checkbox
                                                        id="seasonal"
                                                        checked={this.state.seasonal == "0" || this.state.seasonal == "No" ? false : true}
                                                        onChange={this.handleChange}
                                                    />
                                                }
                                            />

                                        </div>
                                    </>
                                    :
                                    <>
                                        <div style={{ display: 'flex', alignItems: 'center', gap: '16px', marginLeft: '2%' }}>
                                            <TextField
                                                style={{ width: "48.5%" }}
                                                id="amt"
                                                required
                                                label="Amount Once"
                                                value={this.state.amt}
                                                onChange={this.handleChange}
                                            />
                                            <TextField
                                                style={{ width: "48.5%" }}
                                                id="amtDay"
                                                required
                                                label="Amount Daily"
                                                value={this.state.amtDay}
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center', gap: '16px', marginLeft: '2%' }}>
                                            <TextField
                                                style={{ width: "48.5%" }}
                                                id="amtWeek"
                                                required
                                                label="Amount Weekly"
                                                value={this.state.amtWeek}
                                                onChange={this.handleChange}
                                            />
                                            <TextField
                                                style={{ width: "48.5%" }}
                                                id="amtMonth"
                                                required
                                                label="Amount Monthly"
                                                value={this.state.amtMonth}
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                    </>
                                }
                                <div style={{ marginLeft: '2%' }}>
                                    <p>Days of the week discount applies</p>
                                </div>
                                <div style={{ display: 'grid', gridTemplateColumns: "1fr 1fr 1fr", gap: '0px 16px', marginLeft: '2%', marginTop: '-5%' }}>
                                    <FormControlLabel
                                        label={"Sunday"}
                                        control={
                                            <Checkbox
                                                id="sunday"
                                                checked={this.state.sunday == "0" || this.state.sunday == "No" ? false : true}
                                                onChange={this.handleChange}
                                            />
                                        }
                                    />
                                    <FormControlLabel
                                        label={"Monday"}
                                        control={
                                            <Checkbox
                                                id="monday"
                                                checked={this.state.monday == "0" || this.state.monday == "No" ? false : true}
                                                onChange={this.handleChange}
                                            />
                                        }
                                    />
                                    <FormControlLabel
                                        label={"Tuesday"}
                                        control={
                                            <Checkbox
                                                id="tuesday"
                                                checked={this.state.tuesday == "0" || this.state.tuesday == "No" ? false : true}
                                                onChange={this.handleChange}
                                            />
                                        }
                                    />
                                    <FormControlLabel
                                        label={"Wednesday"}
                                        control={
                                            <Checkbox
                                                id="wednesday"
                                                checked={this.state.wednesday == "0" || this.state.wednesday == "No" ? false : true}
                                                onChange={this.handleChange}
                                            />
                                        }
                                    />
                                    <FormControlLabel
                                        label={"Thursday"}
                                        control={
                                            <Checkbox
                                                id="thursday"
                                                checked={this.state.thursday == "0" || this.state.thursday == "No" ? false : true}
                                                onChange={this.handleChange}
                                            />
                                        }
                                    />
                                    <FormControlLabel
                                        label={"Friday"}
                                        control={
                                            <Checkbox
                                                id="friday"
                                                checked={this.state.friday == "0" || this.state.friday == "No" ? false : true}
                                                onChange={this.handleChange}
                                            />
                                        }
                                    />
                                    <FormControlLabel
                                        label={"Saturday"}
                                        control={
                                            <Checkbox
                                                id="saturday"
                                                checked={this.state.saturday == "0" || this.state.saturday == "No" ? false : true}
                                                onChange={this.handleChange}
                                            />
                                        }
                                    />
                                </div>
                                <div style={{ display: 'flex', justifyContent: "center", alignItems: 'center', gap: '16px', marginLeft: '2%' }}>
                                    <Button
                                        style={{ backgroundColor: '#2dcd7a', color: '#000000' }}
                                        onClick={this.state.addNew ? this.addNew : this.saveEdits}
                                        disabled={this.state.addNew ? !this.state.formValid : false}
                                    >
                                        {this.state.addNew ? "Add" : "Update"}
                                    </Button>
                                    <Button
                                        style={{ backgroundColor: '#FDECEB', color: '#DB3737' }}
                                        onClick={() => {
                                            this.setState({ addNew: false, editingDiscountId: null });
                                            this.setState({
                                                discountName: '',
                                                duration: '',
                                                discountPercent: '',
                                                amt: '',
                                                amtDay: '',
                                                amtWeek: '',
                                                amtMonth: '',
                                                sunday: true,
                                                monday: true,
                                                tuesday: true,
                                                wednesday: true,
                                                thursday: true,
                                                friday: true,
                                                saturday: true,
                                                daily: true,
                                                week: false,
                                                month: false,
                                                seasonal: false,
                                                showRemote: false,
                                                active: true,
                                                editingDiscountId: null,
                                            });
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </div>
                        </DialogContent>
                    </Dialog> : ""
                }
                <TableContainer component={Paper} style={{ width: '90%', margin: '0 auto' }}>
                    <Table >
                        <TableHead>
                            <TableRow>
                                <TableCell className='tableCell'>Name</TableCell>
                                <TableCell className='tableCell'>Duration</TableCell>
                                <TableCell className='tableCell'>Percent</TableCell>
                                <TableCell className='tableCell'>Daily</TableCell>
                                <TableCell className='tableCell'>Weekly</TableCell>
                                <TableCell className='tableCell'>Monthly</TableCell>
                                <TableCell className='tableCell'>Seasonal</TableCell>
                                <TableCell className='tableCell'>Amount Once</TableCell>
                                <TableCell className='tableCell'>Day</TableCell>
                                <TableCell className='tableCell'>Week</TableCell>
                                <TableCell className='tableCell'>Month</TableCell>
                                <TableCell className='tableCell'>Active</TableCell>
                                <TableCell className='tableCell'>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.discounts.map((discount, index) => (
                                <TableRow key={index}>
                                    <TableCell className='tableCell'>
                                        {discount.name}
                                    </TableCell>
                                    <TableCell className='tableCell'>
                                        {discount.duration}
                                    </TableCell>
                                    <TableCell className='tableCell'>
                                        {discount.discount_percent}%
                                    </TableCell>
                                    <TableCell className='tableCell'>
                                        {discount.disc_appl_daily ? 'Yes' : 'No'}
                                    </TableCell>
                                    <TableCell className='tableCell'>
                                        {discount.disc_appl_week ? 'Yes' : 'No'}
                                    </TableCell>
                                    <TableCell className='tableCell'>
                                        {discount.disc_appl_month ? 'Yes' : 'No'}
                                    </TableCell>
                                    <TableCell className='tableCell'>
                                        {discount.disc_appl_seasonal ? 'Yes' : 'No'}
                                    </TableCell>
                                    <TableCell className='tableCell'>
                                        ${parseFloat(discount.amount).toFixed(2)}
                                    </TableCell>
                                    <TableCell className='tableCell'>
                                        ${parseFloat(discount.amount_daily).toFixed(2)}
                                    </TableCell>
                                    <TableCell className='tableCell'>
                                        ${parseFloat(discount.amount_weekly).toFixed(2)}
                                    </TableCell>
                                    <TableCell className='tableCell'>
                                        ${parseFloat(discount.amount_monthly).toFixed(2)}
                                    </TableCell>
                                    <TableCell className='tableCell'>
                                        {(discount.active ? 'Yes' : 'No')}
                                    </TableCell>
                                    <TableCell className='tableCell'>
                                        <div>
                                            <Button onClick={() => this.startEditing(discount)} className='viewButtons' style={{ backgroundColor: '#d4d4d4', color: 'black' }}>Edit</Button>
                                            <Button className='viewButtons' onClick={() => this.setState({ deleteId: discount.id, confirmOpen: true })}>
                                                Delete
                                            </Button>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </ThemeProvider>

        );
    };
};
export default withStyles(useStyles)(Discounts);