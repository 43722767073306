import React, { Component } from 'react';
import { Grid, Button, Typography, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
import TextField from '@mui/material/TextField';
import '../CSS/Settings.css';
import { deletePrices, getPrices, createPrices, updatePrices } from '../utils/OpenCampground_Database_Calls'
import { Dialog, DialogContent, DialogTitle, DialogActions } from '@mui/material';
import { withStyles } from '@material-ui/core/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const useStyles = theme => ({
    dialogPaper: {
        padding: theme.spacing(2),
        borderRadius: '10px',
    }
});

const theme = createTheme({
    palette: {
        primary: {
            main: '#2dcd7a',
        },
    },
});

class Prices extends Component {
    constructor(props) {
        super(props);
        this.state = {
            prices: [],
            pricesUpdate: false,
            addNew: false,
            formValid: false,
            editMode: null,
            priceName: '',
            daily: '',
            weekly: '',
            monthly: '',
            seasonal: '',
            open: false,
            popupTitle: '',
            popupText: '',
            deleteId: '',
            confirmOpen: false,
            priceId: '',
            confirmPopupTitle: 'Delete Price Class',
            ConfirmPopupText: 'Please make sure that the price class that you are deleting is not associated with any of the current or future reservations.'
        };
    }

    async componentDidMount() {
        this.prices()
    }

    deleteRecord = async (data) => {
        let deleteResponse = await deletePrices(this.props.user, this.props.serverName, data)
        this.setState({
            pricesUpdate: true,
            open: true,
            popupTitle: 'Delete Price Class',
            popupText: 'Price class deleted successfully.'
        })
        this.prices()
    }

    prices = async () => {
        if (this.state.pricesUpdate) {
            let prices = await getPrices(this.props.user, this.props.serverName)
            this.setState({
                prices: prices
            })
        } else {
            if (this.state.prices.length > 0) {
            } else {
                let prices = await getPrices(this.props.user, this.props.serverName)
                this.setState({
                    prices: prices
                })
            }
        }
    }

    handleChange = (event) => {
        this.setState({
            [event.target.id]: event.target.value
        }, () => {
            this.formValidation();
        });
    }

    formValidation = () => {
        if (this.state.priceName && this.state.daily && this.state.weekly && this.state.monthly && this.state.seasonal) {
            this.setState({
                formValid: true
            });
        } else if (this.state.formValid) {
            this.setState({
                formValid: false
            });
        }
    }

    addNew = async () => {
        await createPrices(this.props.user, this.props.serverName, this.state.priceName, this.state.daily, this.state.weekly, this.state.monthly, this.state.seasonal);
        this.setState({
            pricesUpdate: true,
            addNew: false,
            popupTitle: 'New Price Class',
            popupText: 'New price class added successfully.',
            open: true
        });
        this.prices();
    }

    handleEdit = (price) => {
        this.setState({
            editMode: price.id,
            priceName: price.price_name,
            daily: price.daily_rate,
            weekly: price.weekly_rate,
            monthly: price.monthly_rate,
            priceId: price.price_id,
            seasonal: price.seasonal_rate
        });
    }

    handleSave = async () => {
        await updatePrices(this.props.user, this.props.serverName, this.state.daily, this.state.weekly, this.state.monthly, 
            this.state.editMode, this.state.priceId, this.state.priceName, this.state.seasonal);
        this.setState({
            priceName: '',
            daily: '',
            weekly: '',
            monthly: '',
            seasonal: '',
            priceId: '',
            editMode: null,
            pricesUpdate: true,
            open: true,
            popupTitle: 'Edit Price',
            popupText: 'Price updated successfully.'
        });
        this.prices();
    }

    render() {
        const { classes } = this.props;
        return (
            <ThemeProvider theme={theme}>
                <Dialog open={this.state.confirmOpen} onClick={() => this.setState({ confirmOpen: false })} classes={{ paper: classes.dialogPaper }}>
                    <DialogTitle>{this.state.confirmPopupTitle}</DialogTitle>
                    <DialogContent>
                        {this.state.ConfirmPopupText}
                    </DialogContent>
                    <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button onClick={() => this.setState({ confirmOpen: false })} style={{ backgroundColor: '#FDECEB', color: '#DB3737', padding: '2% 10% 2% 10%', margin: '0 0 0 2%', borderRadius: '10px', marginTop: '3%' }}>
                            Cancel
                        </Button>
                        <Button onClick={() => this.deleteRecord(this.state.deleteId)} style={{ backgroundColor: '#2dcd7a', color: '#000000', padding: '2% 10% 2% 10%', margin: '0 0 0 2%', borderRadius: '10px', marginTop: '3%' }}>
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={this.state.open} onClose={() => this.setState({ open: false })} classes={{ paper: classes.dialogPaper }}>
                    <DialogTitle>{this.state.popupTitle}</DialogTitle>
                    <DialogContent>
                        {this.state.popupText}
                    </DialogContent>
                    <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button onClick={() => this.setState({ open: false })} style={{ backgroundColor: '#FDECEB', color: '#DB3737', padding: '2% 10% 2% 10%', margin: '0 0 0 2%', borderRadius: '10px', marginTop: '3%' }}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

                <Button className='resButton' onClick={() => { this.setState({ addNew: true, }) }} style={{ backgroundColor: '#2dcd7a', color: '#000000', marginBottom: '2%', textTransform: "capitalize" }}>Add New Price</Button>

                {this.state.addNew || this.state.editMode
                    ?
                    <Dialog open={this.state.addNew || this.state.editMode} maxWidth="sm" fullWidth
                        onClose={() => { this.setState({ addNew: false, editMode: null, priceName: '', daily: '', weekly: '', monthly: '' }); }} >
                        <DialogContent dividers>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                                <h2 style={{ margin: "0px", marginBottom: "10px" }}>{this.state.editMode ? "Update Price" : "Add Price"}</h2>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                                    <TextField
                                        id="priceName"
                                        required
                                        label="Price Name"
                                        value={this.state.priceName}
                                        onChange={this.handleChange}
                                    />
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                                    <TextField id="daily" required label="Daily" value={this.state.daily} onChange={this.handleChange} />
                                    <TextField id="weekly" required label="Weekly" value={this.state.weekly} onChange={this.handleChange} />
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                                    <TextField id="monthly" required label="Monthly" value={this.state.monthly} onChange={this.handleChange} />
                                    <TextField id="seasonal" required label="Seasonal" value={this.state.seasonal} onChange={this.handleChange} />
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '16px' }}>
                                    <Button
                                        style={{ backgroundColor: '#2dcd7a', color: '#000000' }}
                                        onClick={this.state.editMode ? this.handleSave : this.addNew}
                                        disabled={this.state.editMode ? false : !this.state.formValid}
                                    >
                                        {this.state.editMode ? "Update" : "Add"}
                                    </Button>
                                    <Button
                                        style={{ backgroundColor: '#FDECEB', color: '#DB3737' }}
                                        onClick={() => {
                                            this.setState({
                                                addNew: false, editMode: null,
                                                priceName: '',
                                                daily: '',
                                                weekly: '',
                                                monthly: '',
                                            })
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </div>
                        </DialogContent>
                    </Dialog>
                    : ""}

                <TableContainer component={Paper} style={{ width: '70%', margin: '0 auto' }}>
                    <Table className='table'>
                        <TableHead>
                            <TableRow>
                                <TableCell className='tableCell'>Season Name</TableCell>
                                <TableCell className='tableCell'>Price</TableCell>
                                <TableCell className='tableCell'>Daily</TableCell>
                                <TableCell className='tableCell'>Weekly</TableCell>
                                <TableCell className='tableCell'>Monthly</TableCell>
                                <TableCell className='tableCell'>Seasonal</TableCell>
                                <TableCell className='tableCell'>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.prices.map((price, index) => (
                                <TableRow key={index}>
                                    <TableCell className='tableCell'>{price.season_name}</TableCell>
                                    <TableCell className='tableCell'>{price.price_name}</TableCell>
                                    <TableCell className='tableCell'>{price.daily_rate}</TableCell>
                                    <TableCell className='tableCell'>{price.weekly_rate}</TableCell>
                                    <TableCell className='tableCell'>{price.monthly_rate}</TableCell>
                                    <TableCell className='tableCell'>{price.seasonal_rate}</TableCell>
                                    {/* <TableCell className='tableCell'>{price.active}</TableCell> */}
                                    <TableCell className='tableCell'>
                                        <div>
                                            <Button
                                                className='viewButtons'
                                                onClick={() => this.handleEdit(price)}
                                                style={{ backgroundColor: '#d4d4d4', color: 'black' }}
                                            >
                                                Edit
                                            </Button>
                                            <Button className='viewButtons' onClick={() => this.setState({ deleteId: price.price_id, confirmOpen: true })}>
                                                Delete
                                            </Button>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </ThemeProvider>
        );
    };
};
export default withStyles(useStyles)(Prices);