import React, { Component } from 'react';
import { Grid, Button, Typography, Box, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import '../CSS/Guests.css';
import { getCampers, getGuestDetails } from '../utils/OpenCampground_Database_Calls';
import { withStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import SearchBar from "material-ui-search-bar";
import { Select, MenuItem } from '@mui/material';
import guests from '../assets/guests.png'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import GuestDetail from './GuestDetail';

const useStyles = theme => ({
    circularProgress: {
        position: 'absolute',
        left: '55%',
        top: '50%',
        color: '#2dcd7a'
    }
});

const theme = createTheme({
    palette: {
        primary: {
            main: '#2dcd7a',
        },
    },
});
class Guests extends Component {

    constructor(props) {
        super(props);
        this.state = {
            allGuests: [],
            guests: [],
            currentPage: 1,
            searchValue: '',
            searchType: 'fname',
            noGuests: false,
            guestDetail: false,
            individualGuestDetails: [],
            open: false,
            popupText: '',
            popupTitle: '',
            noPastGuests: ''
        };
    }

    async componentDidMount() {
        this.getGuests()
    }

    getGuests = async () => {
        let listOfGuests = await getCampers(this.props.user, this.props.serverName);
        this.setState({
            allGuests: listOfGuests,
            guests: listOfGuests,
            noPastGuests: listOfGuests.length === 0
        })
    }

    getGuestsByName = async (value) => {
        let filteredGuests = this.state.allGuests.filter(guest =>
            guest.first_name && guest.first_name.toLowerCase() === value.toLowerCase()
        );
        if (filteredGuests.length < 1) {
            this.setState({
                value: '',
                noGuests: true,
            });
        } else {
            this.setState({
                value: '',
                guests: filteredGuests,
                noGuests: false
            });
        }
    }

    getGuestsByEmail = async (value) => {
        let filteredGuests = this.state.allGuests.filter(guest =>
            guest.email && guest.email.toLowerCase() === value.toLowerCase()
        );
        if (filteredGuests.length < 1) {
            this.setState({
                value: '',
                noGuests: true,
            });
        } else {
            this.setState({
                value: '',
                guests: filteredGuests,
                noGuests: false
            });
        }
    }

    displayAllReservations = async () => {
        this.setState({
            noGuests: false,
            guests: this.state.allGuests
        })
    }

    backToGuest = () => {
        this.getGuests();
        this.setState({ guestDetail: false })
    }

    guestDetails = async (id) => {
        let guestDetails = await getGuestDetails(this.props.user, this.props.serverName, id)
        console.log(guestDetails.body)
        if (guestDetails.body.length > 0) {
            this.setState({
                individualGuestDetails: guestDetails.body,
                guestDetail: true
            })
        } else {
            this.setState({
                open: true,
                popupTitle: 'Alert',
                popupText: 'This reservation was previously destroyed in old system. No further details are available.'
            })
        }
    }

    render() {
        const { classes } = this.props;
        const itemsPerPage = 15;

        const totalPages = Math.ceil(this.state.guests.length / itemsPerPage);
        let currentPage = this.state.currentPage || 1;

        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = Math.min(startIndex + itemsPerPage, this.state.guests.length);

        const reservationsOnPage = this.state.guests.slice(startIndex, endIndex);
        function formatPhoneNumber(phone) {
            if (!phone) return '';
            const cleaned = phone.replace(/\D/g, '');
            if (cleaned.length === 10) {
                return `${cleaned.slice(0, 3)}-${cleaned.slice(3, 6)}-${cleaned.slice(6)}`;
            }
            return phone;
        }

        let guestsRows = reservationsOnPage.map((item) => {
            return (
                <tr key={item.id} className='rows'>
                    <td>{item.first_name} {item.last_name}</td>
                    <td>{item.address}</td>
                    <td>{item.city}</td>
                    <td>{item.mail_code}</td>
                    <td>{formatPhoneNumber(item.phone)}</td>
                    <td>{item.email}</td>
                    <td>
                        <Button
                            onClick={() => this.guestDetails(item.id)}
                            style={{ backgroundColor: '#d4d4d4', color: 'black', padding: '2% 10% 2% 10%', borderRadius: '10px', marginTop: '3%', textTransform: "capitalize" }}
                        >
                            View
                        </Button>
                    </td>
                </tr>
            );
        });

        const paginationButtons = [];
        const maxButtonsToShow = 10;
        let startPage, endPage;

        if (totalPages <= maxButtonsToShow) {
            startPage = 1;
            endPage = totalPages;
        } else {
            const maxPagesBeforeCurrentPage = Math.floor(maxButtonsToShow / 2);
            const maxPagesAfterCurrentPage = Math.ceil(maxButtonsToShow / 2) - 1;
            if (currentPage <= maxPagesBeforeCurrentPage) {
                startPage = 1;
                endPage = maxButtonsToShow;
            } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
                startPage = totalPages - maxButtonsToShow + 1;
                endPage = totalPages;
            } else {
                startPage = currentPage - maxPagesBeforeCurrentPage;
                endPage = currentPage + maxPagesAfterCurrentPage;
            }
        }

        for (let i = startPage; i <= endPage; i++) {
            paginationButtons.push(
                <Button className={`paginationButton ${currentPage === i ? "activePage" : ""}`} key={i} onClick={() => this.setState({ currentPage: i })}>
                    {i}
                </Button>
            );
        }

        const previousButton = (
            <Button className='previousButton' disabled={currentPage === 1} onClick={() => this.setState({ currentPage: currentPage - 1 })}>
                <ArrowBackIosIcon />
            </Button>
        );

        const nextButton = (
            <Button className='nextButton' disabled={currentPage === totalPages} onClick={() => this.setState({ currentPage: currentPage + 1 })}>
                <ArrowForwardIosIcon />
            </Button>
        );

        const lastButton = (
            <Button className='paginationButton' disabled={currentPage === totalPages} onClick={() => this.setState({ currentPage: totalPages })}>
                {totalPages}
            </Button>
        );

        if (this.state.guestDetail) {
            return <Grid className='guestsList'>
                <ThemeProvider theme={theme}>
                    <Box className='infoBox'>
                        <Typography className='infoBarTypo'>Welcome: {this.props.serverName.charAt(0).toUpperCase() + this.props.serverName.slice(1)}</Typography>
                        <Typography className='infoBarTypo'>Username: {this.props.user}</Typography>
                    </Box>
                    <div className='box' style={{ padding: "0px 3%" }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <img src={guests} alt="Guests" style={{ height: '40px', width: '40px' }} />
                            <p className='transactionstypography' style={{ whiteSpace: "nowrap", margin: "0px", marginLeft: '10%' }}>Guest Details</p>
                        </div>
                    </div>
                    <GuestDetail backToGuest={this.backToGuest} individualGuestDetails={this.state.individualGuestDetails} serverName={this.props.serverName} user={this.props.user} />
                </ThemeProvider>
            </Grid>
        }
        else {
            if (this.state.guests.length >= 1 || this.state.noGuests) {
                return (
                    <Grid className='guestsList'>
                        <ThemeProvider theme={theme}>
                            <Dialog open={this.state.open} onClose={this.handleCancel} classes={{ paper: classes.dialogPaper }}>
                                <DialogTitle>{this.state.popupTitle}</DialogTitle>
                                <DialogContent>
                                    {this.state.popupText}
                                </DialogContent>
                                <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Button onClick={() => this.setState({ open: false })} style={{ backgroundColor: '#FDECEB', color: '#DB3737', padding: '2% 10% 2% 10%', margin: '0 0 0 2%', borderRadius: '10px', marginTop: '3%', textTransform: "capitalize" }}>
                                        Close
                                    </Button>
                                </DialogActions>
                            </Dialog>

                            <Box className='infoBox'>
                                <Typography className='infoBarTypo'>Welcome: {this.props.serverName.charAt(0).toUpperCase() + this.props.serverName.slice(1)}</Typography>
                                <Typography className='infoBarTypo'>Username: {this.props.user}</Typography>
                            </Box>
                            <Box className='box'>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img src={guests} alt="Guests" style={{ height: '40px', width: '40px' }} />
                                    <Typography className='transactionstypography' >Guests</Typography>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '4%' }}>
                                    <Select
                                        value={this.state.searchType}
                                        onChange={(e) => this.setState({ searchType: e.target.value })}
                                    >
                                        <MenuItem value="fname">Search By First Name</MenuItem>
                                        <MenuItem value="email">Search By Email</MenuItem>
                                    </Select>
                                    {this.state.searchType === "fname"
                                        ?
                                        <>
                                            <SearchBar
                                                className='searchBox'
                                                placeholder='Search By First Name'
                                                value={this.state.searchValue}
                                                onChange={(newValue) => this.setState({ searchValue: newValue })}
                                                onRequestSearch={() => this.getGuestsByName(this.state.searchValue)}
                                                cancelOnEscape={true}
                                                onCancelSearch={() => this.displayAllReservations()}
                                            />
                                        </>
                                        :
                                        <>
                                            <SearchBar
                                                className='searchBox'
                                                placeholder='Search By Email'
                                                value={this.state.searchValue}
                                                onChange={(newValue) => this.setState({ searchValue: newValue })}
                                                onRequestSearch={() => this.getGuestsByEmail(this.state.searchValue)}
                                                cancelOnEscape={true}
                                                onCancelSearch={() => this.displayAllReservations()}
                                            />
                                        </>
                                    }
                                </div>
                            </Box>
                        </ThemeProvider>

                        <Box className='tableheadingbox'>
                            <Typography className='headerTypo'>Customer Name</Typography>
                            <Typography className='headerTypo'>Address</Typography>
                            <Typography className='headerTypo'>City</Typography>
                            <Typography className='headerTypo'>Postal Code</Typography>
                            <Typography className='headerTypo'>Phone</Typography>
                            <Typography className='headerTypo'>Email</Typography>
                            <Typography className='headerTypo'>Details</Typography>
                        </Box>
                        {!this.state.noGuests
                            ?
                            <>
                                <Box className='tableBox'>
                                    <table>
                                        <tbody>
                                            {guestsRows}
                                        </tbody>
                                    </table>
                                </Box>
                                <div className="pagination">
                                    {previousButton}
                                    {paginationButtons}
                                    ...
                                    {lastButton}
                                    {nextButton}
                                </div>
                            </>
                            :
                            <>
                                <Typography className='headerTypo'>No Transactions Found</Typography>
                            </>
                        }
                    </Grid >
                );
            } else if (this.state.noPastGuests) {
                return (
                    <Grid className='transactionsList'>
                        <Box className='infoBox'>
                            <Typography className='infoBarTypo'>Welcome: {this.props.serverName.charAt(0).toUpperCase() + this.props.serverName.slice(1)}</Typography>
                            <Typography className='infoBarTypo'>Username: {this.props.user}</Typography>
                        </Box>
                        <Box className='box'>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <img src={guests} alt="Guests" style={{ height: '40px', width: '40px' }} />
                                <Typography className='transactionstypography' >Guests</Typography>
                            </div>
                        </Box>
                        <Typography style={{ marginTop: '2%', fontSize: '1.5rem' }}> You do not have any past guests.</Typography>
                    </Grid>
                );
            } else {
                return (
                    <CircularProgress className={classes.circularProgress} />
                );
            }
        }
    };
};

export default withStyles(useStyles)(Guests);