import React, { Component } from 'react';
import { Grid, Button, Typography, Box } from '@material-ui/core';
import '../CSS/Settings.css';
import SiteTypes from './SiteTypes';
import Seasons from './Seasons';
import Prices from './Prices';
import Spaces from './Spaces';
import Discounts from './Discounts';
import RigTypes from './RigTypes';
import Taxes from './Taxes';
import Extras from './Extras';
import Email from './Email';
import MailMessages from './MailMessages';
import PaymentConfiguration from './PaymentConfiguration';
import SystemOptions from './SystemOptions';
import RemoteExplainations from './RemoteExplainations';
import RemoteBlackouts from './RemoteBlackouts'
import settings from '../assets/settings.png'
class Settings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: 'siteTypes',
        };
    }

    render() {
        return (
            <Grid className='settingsPage'>
                <Box className='infoBox'>
                    <Typography className='infoBarTypo'>Welcome: {this.props.serverName.charAt(0).toUpperCase() + this.props.serverName.slice(1)}</Typography>
                    <Typography className='infoBarTypo'>Username: {this.props.user}</Typography>
                </Box>

                <Box className='settingsBox'>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img src={settings} alt="Settings" className='buttonImage' />
                        <Typography className='settingsTypo' style={{ marginLeft: '10%' }}>Settings</Typography>
                    </div>
                </Box>
                <Box className='settingList'>
                    <Box className='settingOptionWrapper'>
                        <Box className={`settingOption ${this.state.show === "siteTypes" ? "activeSettingOption" : ""}`} onClick={() => { this.setState({ show: "siteTypes" }) }}>
                            Site Types
                        </Box>
                        <Box className={`settingOption ${this.state.show === "seasons" ? "activeSettingOption" : ""}`} onClick={() => { this.setState({ show: "seasons" }) }}>
                            Seasons
                        </Box>
                        <Box className={`settingOption ${this.state.show === "prices" ? "activeSettingOption" : ""}`} onClick={() => { this.setState({ show: "prices" }) }}>
                            Prices
                        </Box>
                        <Box className={`settingOption ${this.state.show === "spaces" ? "activeSettingOption" : ""}`} onClick={() => { this.setState({ show: "spaces" }) }}>
                            Spaces
                        </Box>
                        <Box className={`settingOption ${this.state.show === "discounts" ? "activeSettingOption" : ""}`} onClick={() => { this.setState({ show: "discounts" }) }}>
                            Discounts
                        </Box>
                        <Box className={`settingOption ${this.state.show === "rigTypes" ? "activeSettingOption" : ""}`} onClick={() => { this.setState({ show: "rigTypes" }) }}>
                            Rig Types
                        </Box>
                        <Box className={`settingOption ${this.state.show === "taxes" ? "activeSettingOption" : ""}`} onClick={() => { this.setState({ show: "taxes" }); }}>
                            Taxes
                        </Box>
                        <Box className={`settingOption ${this.state.show === "extras" ? "activeSettingOption" : ""}`} onClick={() => { this.setState({ show: 'extras', }); }}>
                            Extras
                        </Box>
                        <Box className={`settingOption ${this.state.show === "email" ? "activeSettingOption" : ""}`} onClick={() => { this.setState({ show: "email" }); }}>
                            Email
                        </Box>
                        <Box className={`settingOption ${this.state.show === "mailMessages" ? "activeSettingOption" : ""}`} onClick={() => { this.setState({ show: "mailMessages" }); }}>
                            Mail Messages
                        </Box>
                        <Box className={`settingOption ${this.state.show === "paymentConfiguration" ? "activeSettingOption" : ""}`} onClick={() => { this.setState({ show: "paymentConfiguration" }); }}>
                            Payment Configuration
                        </Box>
                        <Box className={`settingOption ${this.state.show === "systemOptions" ? "activeSettingOption" : ""}`} onClick={() => { this.setState({ show: "systemOptions" }); }}>
                            System Options
                        </Box>
                        <Box className={`settingOption ${this.state.show === "remoteBlackout" ? "activeSettingOption" : ""}`} onClick={() => { this.setState({ show: "remoteBlackout" }); }}>
                            Online Blackout
                        </Box>
                        <Box className={`settingOption ${this.state.show === "remoteExplainations" ? "activeSettingOption" : ""}`} onClick={() => { this.setState({ show: "remoteExplainations" }); }}>
                            Online Explanations
                        </Box>

                    </Box>
                </Box>
                <Box className='viewBox'>
                    {this.state.show === 'siteTypes'
                        ?
                        <>
                            <SiteTypes serverName={this.props.serverName} user={this.props.user} />
                        </>
                        : null
                    }

                    {this.state.show === 'seasons'
                        ?
                        <>
                            <Seasons serverName={this.props.serverName} user={this.props.user} />
                        </>
                        : null
                    }

                    {this.state.show === 'prices'
                        ?
                        <>
                            <Prices serverName={this.props.serverName} user={this.props.user} />
                        </>
                        : null
                    }

                    {this.state.show === 'spaces'
                        ?
                        <>
                            <Spaces serverName={this.props.serverName} user={this.props.user} />
                        </>
                        : null
                    }

                    {this.state.show === 'discounts'
                        ?
                        <>
                            <Discounts serverName={this.props.serverName} user={this.props.user} />
                        </>
                        : null
                    }

                    {this.state.show === 'rigTypes'
                        ?
                        <>
                            <RigTypes serverName={this.props.serverName} user={this.props.user} />
                        </>
                        : null
                    }

                    {this.state.show === 'taxes'
                        ?
                        <>
                            <Taxes serverName={this.props.serverName} user={this.props.user} />
                        </>
                        : null
                    }

                    {this.state.show === 'extras'
                        ?
                        <>
                            <Extras serverName={this.props.serverName} user={this.props.user} />
                        </>
                        : null
                    }

                    {this.state.show === 'email'
                        ?
                        <>
                            <Email serverName={this.props.serverName} user={this.props.user} />
                        </>
                        : null
                    }

                    {this.state.show === 'mailMessages'
                        ?
                        <>
                            <MailMessages serverName={this.props.serverName} user={this.props.user} />
                        </>
                        : null
                    }

                    {this.state.show === 'paymentConfiguration'
                        ?
                        <>
                            <PaymentConfiguration serverName={this.props.serverName} user={this.props.user} />
                        </>
                        : null
                    }

                    {this.state.show === 'systemOptions'
                        ?
                        <>
                            <SystemOptions serverName={this.props.serverName} user={this.props.user} />
                        </>
                        : null
                    }

                    {this.state.show === 'remoteBlackout'
                        ?
                        <>
                            <RemoteBlackouts serverName={this.props.serverName} user={this.props.user} />
                        </>
                        : null
                    }

                    {this.state.show === 'remoteExplainations'
                        ?
                        <>
                            <RemoteExplainations serverName={this.props.serverName} user={this.props.user} />
                        </>
                        : null
                    }
                </Box>
            </Grid >
        );
    };
};
export default Settings;