import React, { Component } from 'react';
import { Grid, Button, Typography, Box } from '@material-ui/core';
import '../CSS/NewReservation.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import siteSelect from '../assets/site_select.png'
import GroupReservationUserDetails from './GroupReservationUserDetails'

const theme = createTheme({
    palette: {
        primary: {
            main: '#2dcd7a',
        },
    },
});

class GroupReservationSpaceSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectSpace: true,
            spacesSelected: []
        };
    }

    handleSelect = async (space) => {
        if (this.state.spacesSelected.some(selected => selected.siteId === space.id)) {
            this.setState({
                spacesSelected: this.state.spacesSelected.filter(selected => selected.siteId !== space.id)
            });
        } else {
            const newSpace = {
                spaceName: space.space_name,
                siteType: space.sitetype_name,
                daily: space.daily_rate,
                weekly: space.weekly_rate,
                monthly: space.monthly_rate,
                siteId: space.id,
            };
            this.setState({
                spacesSelected: [...this.state.spacesSelected, newSpace],
            });
        }
    }

    backToSelectSpace = () => {
        this.setState({
            selectSpace: true,
            userDetails: false,
        })
    }

    render() {
        const spaceRows = this.props.spaceData.map((space, index) => {
            const isSelected = this.state.spacesSelected.some(selected => selected.siteId === space.id);
            return (
                <tr key={index} className='rows'>
                    <td className='headerTypo'>{space.space_name}</td>
                    <td className='headerTypo'>{space.sitetype_name}</td>
                    <td className='headerTypo'>
                        {space.power_50a ? 'Electric (50A)' : space.power_30a ? 'Electric (30A)' : space.power_15a ? 'Electric (15A)' : 'No Electric'}
                    </td>
                    <td className='headerTypo'>{space.water ? 'Water' : 'No Water'}</td>
                    <td className='headerTypo'>{space.sewer ? 'Sewer' : 'No Sewer'}</td>
                    <td className='headerTypo'>${parseFloat(space.daily_rate).toFixed(2)}</td>
                    <td className='headerTypo'>${parseFloat(space.weekly_rate).toFixed(2)}</td>
                    <td className='headerTypo'>${parseFloat(space.monthly_rate).toFixed(2)}</td>
                    <td>
                        <Button
                            className='selectButton'
                            onClick={() => this.handleSelect(space)}
                            style={{
                                backgroundColor: isSelected ? '#fff' : '#2dcd7a',
                                color: isSelected ? '#000' : '#000000',
                                padding: '2% 10% 2% 10%',
                                borderRadius: '10px',
                                textTransform: "capitalize",
                                border: isSelected ? '1px solid black' : null,
                            }}
                        >
                            {isSelected ? 'Selected' : 'Select'}
                        </Button>
                    </td>
                </tr>
            );
        });
        return (
            <Grid className='reservationList' style={{ width: '100%' }}>
                {this.state.selectSpace
                    ?
                    <>
                        <Box className='box' style={{ marginRight: '-6%' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <img src={siteSelect} alt="Reservations" style={{ height: '40px', marginRight: '8px' }} />
                                <Typography className='reservationstypography'>Select Sites</Typography>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '27%', marginRight: '2%' }}>
                                <Button className='cancel' onClick={() => { this.props.history.replace(`/manager?serverName=${this.props.serverName}&view=reservations`) }}>Cancel Reservation</Button>
                                <Button className='cancel' disabled={!(this.state.spacesSelected.length > 1)}
                                    style={{
                                        backgroundColor: !(this.state.spacesSelected.length > 1)
                                            ? "#999999"
                                            : "#2dcd7a",
                                        color: !(this.state.spacesSelected.length > 1)
                                            ? "#000000"
                                            : "#000000"
                                    }}
                                    onClick={() => { this.setState({ selectSpace: false }) }}>Continue Booking</Button>
                            </div>
                        </Box>

                        <Box className='numberofresultsbox' style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography className='typography'>{this.props.spaceData.length} Spaces Available</Typography>
                            <Typography className='typography'>
                                Spaces Selected ({this.state.spacesSelected.length}): {this.state.spacesSelected.length > 0
                                    ? this.state.spacesSelected.map(space => space.spaceName).join(', ')
                                    : 'None'}
                            </Typography>
                        </Box>

                        <Box className='spaceHeadingBox'>
                            <Typography className='headerTypo'>Space Name</Typography>
                            <Typography className='headerTypo'>Site Type</Typography>
                            <Typography className='headerTypo'>Electric</Typography>
                            <Typography className='headerTypo'>Water</Typography>
                            <Typography className='headerTypo'>Sewer</Typography>
                            <Typography className='headerTypo'>Daily</Typography>
                            <Typography className='headerTypo'>Weekly</Typography>
                            <Typography className='headerTypo'>Monthly</Typography>
                        </Box>

                        <Box className='scrollContainer'>
                            <Box className='spacetableBox'>
                                <table>
                                    <tbody>
                                        {spaceRows}
                                    </tbody>
                                </table>
                            </Box>
                        </Box>
                    </>
                    : <GroupReservationUserDetails sites={this.props.sites} rigs={this.props.rigs} checkIn={this.props.checkIn} checkOut={this.props.checkOut}
                        cancelReservation={this.props.cancelReservation} guests={this.props.guests} rigType={this.props.rigType} pets={this.props.pets}
                        children={this.props.children} serverName={this.props.serverName} backToSelectSpace={this.backToSelectSpace} spacesSelected={this.state.spacesSelected}
                        user={this.props.user}
                    />
                }
            </Grid>
        )
    };
};

export default withRouter(GroupReservationSpaceSelect);