import React, { Component } from 'react';
import {
    Button, TextField, Checkbox, FormControlLabel,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Paper, Select, MenuItem, InputLabel, FormControl, TextareaAutosize,
    Dialog, DialogActions, DialogContent, DialogTitle
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import '../CSS/Settings.css';
import { deleteRecord, getSettingSpaces, createNewSpace, getSitePrice, updateSpace } from '../utils/OpenCampground_Database_Calls';
import { withStyles } from '@material-ui/core/styles';

const useStyles = theme => ({
    dialogPaper: {
        padding: theme.spacing(2),
        borderRadius: '10px',
    }
});

const theme = createTheme({
    palette: {
        primary: {
            main: '#2dcd7a',
        },
    },
});

class Spaces extends Component {
    constructor(props) {
        super(props);
        this.state = {
            spaces: [],
            spacesUpdate: false,
            sites: [],
            prices: [],
            formValid: false,
            spaceName: '',
            e15a: false,
            e30a: false,
            e50a: false,
            water: false,
            sewer: false,
            siteType: '',
            price: '',
            remote: true,
            len: '0',
            width: '102',
            addNew: false,
            editMode: false,
            editingSpace: null,
            special_condition: '',
            active: false,
            open: false,
            popupTitle: '',
            popupText: '',
            deleteId: '',
            confirmOpen: false,
            confirmPopupTitle: 'Delete Space',
            ConfirmPopupText: 'Please make sure that the space that you are deleting is not associated with any of the current or future reservations.'
        };
    }

    async componentDidMount() {
        let getSitePriceData = await getSitePrice(this.props.user, this.props.serverName);
        this.setState({
            sites: getSitePriceData[0],
            prices: getSitePriceData[1]
        });
        this.spaces();
    }

    new = async () => {
        this.setState({
            addNew: true
        });
    }

    handleChange = (event) => {
        if (event.target.id === 'e15a' || event.target.id === 'e30a' || event.target.id === 'e50a' || event.target.id === 'water' || event.target.id === 'sewer' || event.target.id === 'remote' || event.target.id === 'active') {
            this.setState({
                [event.target.id]: event.target.checked
            });
        } else {
            this.setState({
                [event.target.id]: event.target.value
            }, () => {
                this.formValidation();
            });
        }
    }

    formValidation = () => {
        const { spaceName, siteType, price, len, width } = this.state;
        if (spaceName && siteType && price && len && width) {
            this.setState({
                formValid: true
            });
        } else if (this.state.formValid) {
            this.setState({
                formValid: false
            });
        }
    }

    deleteRecord = async (data) => {
        await deleteRecord(this.props.user, this.props.serverName, "spaces", data);
        this.setState({
            spacesUpdate: true,
            open: true,
            popupTitle: 'Delete Space',
            popupText: 'Space deleted successfully.'
        });
        this.spaces();
    }

    spaces = async () => {
        if (this.state.spacesUpdate) {
            let getSpaceData = await getSettingSpaces(this.props.user, this.props.serverName);
            this.setState({
                spaces: getSpaceData
            });
        } else {
            if (this.state.spaces.length === 0) {
                let getSpaceData = await getSettingSpaces(this.props.user, this.props.serverName);
                this.setState({
                    spaces: getSpaceData
                });
            }
        }
    }

    addNew = async () => {
        let spaceExists = this.state.spaces.some(space => space.name.trim().toLowerCase() === this.state.spaceName.trim().toLowerCase());
        if (spaceExists) {
            this.setState({
                open: true,
                popupText: 'This space name already exists. Please choose a different name.'
            })
            return;
        }

        let siteId = this.state.sites.find(site => site.name === this.state.siteType)?.id;
        let priceId = this.state.prices.find(price => price.name === this.state.price)?.id;
        await createNewSpace(this.props.user, this.props.serverName, this.state.spaceName, this.state.e15a, this.state.e30a, this.state.e50a,
            this.state.water, this.state.sewer, this.state.len, this.state.width, siteId, priceId, this.state.remote, this.state.special_condition, this.state.active);
        this.setState({
            spacesUpdate: true,
            addNew: false,
            spaceName: '',
            e15a: false,
            e30a: false,
            e50a: false,
            water: false,
            sewer: false,
            siteType: '',
            price: '',
            remote: true,
            len: '0',
            width: '102',
            special_condition: '',
            active: false,

            popupTitle: 'New Space',
            popupText: 'New space added successfully.',
            open: true
        });
        this.spaces();
    }

    updateSpace = async () => {
        let siteId = this.state.sites.find(site => site.name === this.state.siteType)?.id;
        let priceId = this.state.prices.find(price => price.name === this.state.price)?.id;
        let result = await updateSpace(this.props.user, this.props.serverName, this.state.spaceName, this.state.e15a, this.state.e30a, this.state.e50a,
            this.state.water, this.state.sewer, this.state.len, this.state.width, siteId, priceId, this.state.remote, this.state.editingSpace.id, this.state.special_condition, this.state.active);
        console.log(result)
        this.setState({
            spacesUpdate: true,
            editMode: false,
            editingSpace: null,
            addNew: false,
            spaceName: '',
            e15a: false,
            e30a: false,
            e50a: false,
            water: false,
            sewer: false,
            siteType: '',
            price: '',
            remote: true,
            len: '0',
            width: '102',
            special_condition: '',
            active: false,
            open: true,
            popupTitle: 'Edit Space',
            popupText: 'Space updated successfully.'
        });
        this.spaces();
    }

    siteChange = (event) => {
        this.setState({
            siteType: event.target.value
        }, () => {
            this.formValidation();
        });
    };

    priceChange = (event) => {
        this.setState({
            price: event.target.value
        }, () => {
            this.formValidation();
        });
    };

    editSpace = (space) => {
        this.setState({
            editMode: true,
            addNew: false,
            editingSpace: space,
            spaceName: space.name,
            e15a: space.power_15a,
            e30a: space.power_30a,
            e50a: space.power_50a,
            water: space.water,
            sewer: space.sewer,
            siteType: space.site_name,
            price: space.price_name,
            remote: space.remote_reservable,
            len: space.length,
            width: space.width,
            special_condition: space.special_condition,
            active: space.active
        });
    }

    cancelEdit = () => {
        this.setState({
            editMode: false,
            editingSpace: null,
            spaceName: '',
            e15a: false,
            e30a: false,
            e50a: false,
            water: false,
            sewer: false,
            siteType: '',
            price: '',
            remote: true,
            len: '0',
            width: '102',
            special_condition: '',
            active: false
        });
    }

    handleCancel = () => {
        this.setState({ open: false });
    };

    render() {
        const { classes } = this.props;
        return (
            <ThemeProvider theme={theme}>
                <Dialog open={this.state.confirmOpen} onClick={() => this.setState({ confirmOpen: false })} classes={{ paper: classes.dialogPaper }}>
                    <DialogTitle>{this.state.confirmPopupTitle}</DialogTitle>
                    <DialogContent>
                        {this.state.ConfirmPopupText}
                    </DialogContent>
                    <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button onClick={() => this.setState({ confirmOpen: false })} style={{ backgroundColor: '#FDECEB', color: '#DB3737', padding: '2% 10% 2% 10%', margin: '0 0 0 2%', borderRadius: '10px', marginTop: '3%' }}>
                            Cancel
                        </Button>
                        <Button onClick={() => this.deleteRecord(this.state.deleteId)} style={{ backgroundColor: '#2dcd7a', color: '#000000', padding: '2% 10% 2% 10%', margin: '0 0 0 2%', borderRadius: '10px', marginTop: '3%' }}>
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={this.state.open} onClose={this.handleCancel} classes={{ paper: classes.dialogPaper }}>
                    <DialogTitle>{this.state.popupTitle}</DialogTitle>
                    <DialogContent>
                        {this.state.popupText}
                    </DialogContent>
                    <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button onClick={this.handleCancel} style={{ backgroundColor: '#FDECEB', color: '#DB3737', padding: '2% 10% 2% 10%', margin: '0 0 0 2%', borderRadius: '10px', marginTop: '3%' }}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
                <Button className='resButton' onClick={this.new} style={{ backgroundColor: '#2dcd7a', color: '#000000', marginBottom: '2%', textTransform: "capitalize" }}>
                    Add New Space
                </Button>
                {this.state.addNew || this.state.editMode
                    ?
                    <>
                        <Dialog open={this.state.addNew || this.state.editMode} onClose={() => { this.setState({ addNew: false, editMode: false }); }} maxWidth="sm" fullWidth>
                            <DialogContent dividers>
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                                    <h2 style={{ margin: "0px", marginBottom: "10px" }}>{this.state.editMode ? "Update Space" : "Add Space"}</h2>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '16px', width: '100%' }}>
                                        <TextField
                                            id="spaceName"
                                            required
                                            label="Space Name"
                                            value={this.state.spaceName}
                                            onChange={this.handleChange}
                                            sx={{ width: "100%" }}
                                        />
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                                        <FormControlLabel
                                            label={"Power 15a"}
                                            control={
                                                <Checkbox
                                                    id="e15a"
                                                    checked={this.state.e15a}
                                                    onChange={this.handleChange}
                                                />
                                            }
                                        />
                                        <FormControlLabel
                                            label={"Power 30a"}
                                            control={
                                                <Checkbox
                                                    id="e30a"
                                                    checked={this.state.e30a}
                                                    onChange={this.handleChange}
                                                />
                                            }
                                        />
                                        <FormControlLabel
                                            label={"Power 50a"}
                                            control={
                                                <Checkbox
                                                    id="e50a"
                                                    checked={this.state.e50a}
                                                    onChange={this.handleChange}
                                                />
                                            }
                                        />
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                                        <FormControlLabel
                                            label={"Water"}
                                            control={
                                                <Checkbox
                                                    id="water"
                                                    checked={this.state.water}
                                                    onChange={this.handleChange}
                                                />
                                            }
                                        />
                                        <FormControlLabel
                                            label={"Sewer"}
                                            control={
                                                <Checkbox
                                                    id="sewer"
                                                    checked={this.state.sewer}
                                                    onChange={this.handleChange}
                                                />
                                            }
                                        />
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                                        <FormControl style={{ width: '50%' }}>
                                            <InputLabel>Site Type</InputLabel>
                                            <Select
                                                labelId="site"
                                                id="siteSelect"
                                                value={this.state.siteType}
                                                label="Site Type"
                                                onChange={this.siteChange}
                                            >
                                                {this.state.sites.map((site, index) => (
                                                    <MenuItem key={index} value={site.name}>
                                                        {site.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <FormControl style={{ width: '50%' }}>
                                            <InputLabel>Price Class</InputLabel>
                                            <Select
                                                labelId="price"
                                                id="priceSelect"
                                                value={this.state.price}
                                                label="Price Class"
                                                onChange={this.priceChange}
                                            >
                                                {this.state.prices.map((price, index) => (
                                                    <MenuItem key={index} value={price.name}>
                                                        {price.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                                        <TextField
                                            style={{ width: '50%' }}
                                            id="len"
                                            label="Length"
                                            type="number"
                                            value={this.state.len}
                                            onChange={this.handleChange}
                                        />
                                        <TextField
                                            style={{ width: '50%' }}
                                            id="width"
                                            label="Width"
                                            type="number"
                                            value={this.state.width}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                                        <FormControlLabel
                                            label={"Online Reservable"}
                                            control={
                                                <Checkbox
                                                    id="remote"
                                                    checked={this.state.remote}
                                                    onChange={this.handleChange}
                                                />
                                            }
                                        />
                                        <FormControlLabel
                                            label={"Active"}
                                            control={
                                                <Checkbox
                                                    id="active"
                                                    checked={this.state.active}
                                                    onChange={this.handleChange}
                                                />
                                            }
                                        />
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '16px', whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
                                        <TextareaAutosize id="special_condition" placeholder="Space Description" onChange={this.handleChange} />
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <Button onClick={this.state.editMode ? this.updateSpace : this.addNew} disabled={this.state.editMode ? false : (!this.state.formValid)} style={{ backgroundColor: '#2dcd7a', color: '#000000' }}>{this.state.editMode ? "Update" : "Add"} </Button>
                                        <Button onClick={() => { this.setState({ addNew: false, editMode: false }); this.cancelEdit() }} style={{ backgroundColor: '#FDECEB', color: '#DB3737', marginLeft: '8px' }}>Cancel</Button>
                                    </div>
                                </div>
                            </DialogContent>
                        </Dialog>
                    </> : ""
                }
                <>
                    <TableContainer component={Paper} style={{ width: '90%', margin: '0 auto' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Space Name</TableCell>
                                    <TableCell>Power 15a</TableCell>
                                    <TableCell>Power 30a</TableCell>
                                    <TableCell>Power 50a</TableCell>
                                    <TableCell>Water</TableCell>
                                    <TableCell>Sewer</TableCell>
                                    <TableCell>Site Type</TableCell>
                                    <TableCell>Price Class</TableCell>
                                    <TableCell>Online Reservable</TableCell>
                                    <TableCell>Space Description</TableCell>
                                    <TableCell>Active</TableCell>
                                    <TableCell>Length</TableCell>
                                    <TableCell>Width</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.spaces.map((space) => (
                                    <TableRow key={space.id}>
                                        <TableCell>
                                            {space.name}</TableCell>
                                        <TableCell>
                                            {(space.power_15a ? 'Yes' : 'No')}</TableCell>
                                        <TableCell>
                                            {(space.power_30a ? 'Yes' : 'No')}</TableCell>
                                        <TableCell>
                                            {(space.power_50a ? 'Yes' : 'No')}</TableCell>
                                        <TableCell>
                                            {(space.water ? 'Yes' : 'No')}</TableCell>
                                        <TableCell>
                                            {(space.sewer ? 'Yes' : 'No')}</TableCell>
                                        <TableCell>
                                            {space.site_name}</TableCell>
                                        <TableCell>
                                            {space.price_name}</TableCell>
                                        <TableCell>
                                            {(space.remote_reservable ? 'Yes' : 'No')}</TableCell>
                                        <TableCell>
                                            {space.special_condition}</TableCell>
                                        <TableCell>
                                            {(space.active ? 'Yes' : 'No')}</TableCell>
                                        <TableCell>
                                            {space.length}</TableCell>
                                        <TableCell>
                                            {space.width}</TableCell>
                                        <TableCell>


                                            <div>
                                                <Button onClick={() => this.editSpace(space)} className='viewButtons' style={{ backgroundColor: '#d4d4d4', color: 'black' }}>Edit</Button>
                                                <Button className='viewButtons' onClick={() => this.setState({ deleteId: space.id, confirmOpen: true })}>
                                                    Delete
                                                </Button>
                                            </div>

                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            </ThemeProvider>
        );
    }
}

export default withStyles(useStyles)(Spaces);
