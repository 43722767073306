import React, { Component } from 'react';
import { Grid, Button, Typography, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import '../CSS/Settings.css';
import TextField from '@mui/material/TextField';
import { getEmails, changeEmails } from '../utils/OpenCampground_Database_Calls'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { withStyles } from '@material-ui/core/styles';
import { Box } from '@mui/material';

const useStyles = theme => ({
    dialogPaper: {
        padding: theme.spacing(2),
        borderRadius: '10px',
    }
});

const theme = createTheme({
    palette: {
        primary: {
            main: '#2dcd7a',
        },
    },
});

class Email extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: [],
            sender: '',
            reply: '',
            cc: '',
            bcc: '',
            open: false,
            popupText: ''
        };
    }

    async componentDidMount() {
        this.email()
    }

    email = async () => {
        if (this.state.emailUpdate) {
            let email = await getEmails(this.props.user, this.props.serverName)
            this.setState({
                sender: email.sender,
                reply: email.reply,
                cc: email.cc,
                bcc: email.bcc,
                email: email
            })
        } else {
            if (this.state.email.length > 0) {
            } else {
                let email = await getEmails(this.props.user, this.props.serverName)
                this.setState({
                    email: email,
                    sender: email.sender,
                    reply: email.reply,
                    cc: email.cc,
                    bcc: email.bcc,
                    email: email
                })
            }
        }
    }

    change = (event) => {
        this.setState({
            [event.target.id]: event.target.value
        })
    }

    saveEmailChanges = async () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (
            (this.state.sender && !emailRegex.test(this.state.sender)) ||
            (this.state.reply && !emailRegex.test(this.state.reply)) ||
            (this.state.cc && !emailRegex.test(this.state.cc)) ||
            (this.state.bcc && !emailRegex.test(this.state.bcc))
        ) {
            this.setState({
                popupText: 'One or more email addresses are invalid.',
                open: true,
                emailUpdate: false,
            });
            return;
        }
        let saveresponse = await changeEmails(this.props.user, this.props.serverName, this.state.sender, this.state.reply, this.state.cc, this.state.bcc)
        this.setState({
            emailUpdate: true,
            popupText: 'Email changes successful.',
            open: true
        })
    }

    handleCancel = () => {
        this.setState({ open: false });
    };

    render() {
        const { classes } = this.props;
        return (
            <ThemeProvider theme={theme}>
                <Dialog open={this.state.open} onClose={this.handleCancel} classes={{ paper: classes.dialogPaper }}>
                    <DialogTitle>{this.state.popupTitle}</DialogTitle>
                    <DialogContent>
                        {this.state.popupText}
                    </DialogContent>
                    <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button onClick={this.handleCancel} style={{ backgroundColor: '#FDECEB', color: '#DB3737', padding: '2% 10% 2% 10%', margin: '0 0 0 2%', borderRadius: '10px', marginTop: '3%' }}>
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
                <div className="email-settings-section">
                    <Typography style={{ fontSize: '1.5rem' }}>Mail Configuration</Typography>
                    <div className="settings-group">
                        <div className="setting-row" style={{ width: '140%' }}>
                            <Box>
                                <Typography className="settingsFieldsTypo" style={{ marginLeft: '-20%' }}>Mail Sender Address</Typography>
                                <TextField
                                    id="sender"
                                    value={this.state.sender}
                                    onChange={this.change}
                                    style={{ width: '120%', marginLeft: '-20%' }}
                                />
                            </Box>
                            <Box>
                                <Typography className="settingsFieldsTypo">Reply-To Address</Typography>
                                <TextField
                                    id="reply"
                                    value={this.state.reply}
                                    onChange={this.change}
                                    style={{ width: '120%' }}
                                />
                            </Box>
                        </div>
                        <div className="setting-row" style={{ width: '140%' }}>
                            <Box>
                                <Typography className="settingsFieldsTypo" style={{ marginLeft: '-20%' }}>Mail CC Address</Typography>
                                <TextField
                                    id="cc"
                                    value={this.state.cc}
                                    onChange={this.change}
                                    style={{ width: '120%', marginLeft: '-20%' }}
                                />
                            </Box>
                            <Box>
                                <Typography className="settingsFieldsTypo">Mail BCC Address</Typography>
                                <TextField
                                    id="bcc"
                                    value={this.state.bcc}
                                    onChange={this.change}
                                    style={{ width: '120%' }}
                                />
                            </Box>
                        </div>
                        <div className="setting-row">
                            <Button className='viewButtons' style={{ whiteSpace: 'nowrap', backgroundColor: '#2dcd7a', color: '#000000' }} onClick={this.saveEmailChanges}>Save</Button>
                        </div>
                    </div>
                </div>
            </ThemeProvider>
        );
    };
};
export default withStyles(useStyles)(Email);