import React, { Component } from 'react';
import { Grid, Button, Typography, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import '../CSS/Settings.css';
import { getRemoteReservationsStatus, updateRemoteReservationsStatus, saveParkMap, getParkMapURL, deleteParkMap } from '../utils/OpenCampground_Database_Calls';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

const useStyles = theme => ({
    dialogPaper: {
        padding: theme.spacing(2),
        borderRadius: '10px',
    }
});

const theme = createTheme({
    palette: {
        primary: {
            main: '#2dcd7a',
        },
    },
});

class SystemOptions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            remote: null,
            remoteReservationPaymentConfig: null,
            open: false,
            popupTitle: '',
            popupText: '',
            selectedImage: null,
            mapURL: null,
            imageFileName: null,
            useSeasonal: false,
            seasonStart: null,
            seasonEnd: null
        };
    }

    async componentDidMount() {
        let remoteActive = await getRemoteReservationsStatus(this.props.user, this.props.serverName);
        this.getMapURL();
        if (remoteActive[0].use_remote_reservations === 1) {
            let paymentConfig;
            if (remoteActive[0].require_gateway === 1) {
                paymentConfig = "require";
            } else if (remoteActive[0].allow_gateway === 1) {
                paymentConfig = "allow";
            } else {
                paymentConfig = "doNotAllow";
            }
            this.setState({
                remote: true,
                remoteReservationPaymentConfig: paymentConfig,
                useSeasonal: remoteActive[0].use_seasonal === 1 ? true : false,
                seasonStart: remoteActive[0].use_seasonal === 1 ? dayjs(remoteActive[0].season_start) : null,
                seasonEnd: remoteActive[0].use_seasonal === 1 ? dayjs(remoteActive[0].season_end) : null
            });
        } else {
            this.setState({
                remote: false,
                remoteReservationPaymentConfig: "require",
                useSeasonal: remoteActive[0].use_seasonal === 1 ? true : false,
                seasonStart: remoteActive[0].use_seasonal === 1 ? dayjs(remoteActive[0].season_start) : null,
                seasonEnd: remoteActive[0].use_seasonal === 1 ? dayjs(remoteActive[0].season_end) : null
            });
        }
    }

    getMapURL = async () => {
        let getMapURL = await getParkMapURL(this.props.user, this.props.serverName)
        this.setState({
            mapURL: getMapURL.body.imageUrl,
            imageFileName: getMapURL.body.fileName
        });
    }

    saveSystemOptions = async () => {
        let saveresponse = await updateRemoteReservationsStatus(this.props.user, this.props.serverName, this.state.remote, this.state.remoteReservationPaymentConfig,
            this.state.useSeasonal, this.state.seasonStart === null ? null : this.state.seasonStart.format("YYYY-MM-DD"), this.state.seasonEnd === null ? null : this.state.seasonEnd.format("YYYY-MM-DD"));
        this.setState({
            popupTitle: 'System Options',
            popupText: 'System options updated successfully.',
            open: true
        });
    };

    handleCancel = () => {
        this.setState({ open: false });
    };

    handleChange = (event) => {
        if (event.target.id === 'remote') {
            this.setState({
                [event.target.id]: event.target.checked
            });
        } else if (event.target.id === 'useSeasonal') {
            this.setState({
                [event.target.id]: event.target.checked,
                seasonStart: event.target.checked ? this.state.seasonStart : null,
                seasonEnd: event.target.checked ? this.state.seasonEnd : null
            });
        }
    };

    isRadioChecked = (value) => {
        return this.state.remoteReservationPaymentConfig === value;
    };

    handleImageChange = (event) => {
        const file = event.target.files[0];
        this.setState({
            selectedImage: file,
        });
    };

    uploadParkMap = async () => {
        let imageUploadStatus = await saveParkMap(this.props.user, this.props.serverName, this.state.selectedImage)
        if (imageUploadStatus.statusCode === 200) {
            this.setState({
                popupTitle: 'Park Map',
                popupText: 'Park map has been uploaded successfully.',
                open: true
            })
            this.getMapURL();
        } else {
            this.setState({
                popupTitle: 'Park Map',
                popupText: 'Park map cannot be uploaded. Please try again.',
                open: true
            })
        }
    };

    deleteMap = async () => {
        let deleteResponse = await deleteParkMap(this.props.user, this.props.serverName, this.state.imageFileName)
        console.log(deleteResponse)
        this.setState({
            mapURL: null,
            imageFileName: null,
            popupTitle: 'Park Map',
            popupText: 'Park map deleted successfully.',
            open: true
        })
    }

    render() {
        const { classes } = this.props;
        return (
            <>
                <ThemeProvider theme={theme}>
                    <Dialog open={this.state.open} onClose={this.handleCancel} classes={{ paper: classes.dialogPaper }}>
                        <DialogTitle>{this.state.popupTitle}</DialogTitle>
                        <DialogContent>
                            {this.state.popupText}
                        </DialogContent>
                        <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Button onClick={this.handleCancel} style={{ backgroundColor: '#FDECEB', color: '#DB3737', padding: '2% 10% 2% 10%', margin: '0 0 0 2%', borderRadius: '10px', marginTop: '3%' }}>
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <div className='paymentConfig' style={{ width: '90%', marginLeft: '2%', marginBottom: '2%' }}>
                        <div className='row' >
                            <Typography style={{ fontSize: '1.3rem' }}>Manager Reservations Settings</Typography>
                        </div>
                        <div className='row'>
                            <FormControlLabel
                                label={"Use Seasonal rates"}
                                control={
                                    <Checkbox
                                        id="useSeasonal"
                                        checked={this.state.useSeasonal}
                                        onChange={this.handleChange}
                                    />
                                }
                            />
                        </div>
                        {this.state.useSeasonal &&
                            <>
                                <div style={{ display: 'flex', justifyContent: 'left', gap: '2%' }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            id="seasonStart"
                                            label="Seasonal Start"
                                            value={this.state.seasonStart}
                                            onChange={(date) => {
                                                this.setState({ seasonStart: date });
                                            }}
                                        />
                                    </LocalizationProvider>

                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            id="seasonEnd"
                                            label="Seasonal End"
                                            value={this.state.seasonEnd}
                                            minDate={dayjs(this.state.seasonStart).add(1, "day")}
                                            onChange={(date) => {
                                                this.setState({ seasonEnd: date });
                                            }}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </>
                        }
                    </div>

                    <div className='paymentConfig' style={{ width: '90%', marginLeft: '2%' }}>
                        <div className='row'>
                            <Typography style={{ fontSize: '1.3rem' }}>Online Reservations Settings</Typography>
                        </div>
                        <div className='row'>
                            <FormControlLabel
                                label={"Allow Online Reservations"}
                                control={
                                    <Checkbox
                                        id="remote"
                                        checked={this.state.remote}
                                        onChange={this.handleChange}
                                    />
                                }
                            />
                        </div>
                        {this.state.remote &&
                            <>
                                <div className='row' style={{ marginLeft: '-1%' }}>
                                    <label>
                                        <Radio
                                            name='option'
                                            value='require'
                                            checked={this.isRadioChecked('require')}
                                            onChange={() => { this.setState({ remoteReservationPaymentConfig: 'require' }) }}
                                        />
                                        Online Reservations Require Payment
                                    </label>
                                    <label>
                                        <Radio
                                            name='option'
                                            value='allow'
                                            checked={this.isRadioChecked('allow')}
                                            onChange={() => { this.setState({ remoteReservationPaymentConfig: 'allow' }) }}
                                        />
                                        Online Reservations Allow Payment
                                    </label>
                                    <label>
                                        <Radio
                                            name='option'
                                            value='doNotAllow'
                                            checked={this.isRadioChecked('doNotAllow')}
                                            onChange={() => { this.setState({ remoteReservationPaymentConfig: 'doNotAllow' }) }}
                                        />
                                        Online Reservations do not use Payment
                                    </label>
                                </div>
                                <div className='row'>
                                    <Typography style={{ textAlign: 'left', width: '100%' }}>
                                        Use this URL (
                                        <a
                                            href={`https://www.app.opencampground.com/remote?serverName=${this.props.serverName}`}
                                            target="_blank"
                                            // rel="noopener noreferrer"
                                            style={{ textDecoration: 'none', color: 'blue' }}
                                        >
                                            {`https://www.app.opencampground.com/remote?serverName=${this.props.serverName}`}
                                        </a>
                                        ) for Online Reservations. Please see our knowledge base for instructions.
                                    </Typography>
                                </div>
                            </>
                        }
                    </div>

                    <div style={{ width: '90%', marginLeft: '2%', display: 'flex', justifyContent: 'left' }}>
                        <Button style={{ backgroundColor: '#2dcd7a', color: '#000000', marginTop: '3%' }} className='viewButtons' onClick={this.saveSystemOptions}>Save</Button>
                    </div>
                    <hr style={{ width: '90%', border: '1px solid rgba(159, 159, 159, 255)', margin: '2% 0 2% 2%' }} />
                    {this.state.mapURL === null
                        ?
                        <div style={{ width: '90%', marginLeft: '2%' }}>
                            <Typography style={{ fontSize: '1.3rem', marginBottom: '1rem', textAlign: 'left' }}>Upload Park Map</Typography>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                                <input type="file" accept="image/*" onChange={this.handleImageChange} />
                                <Button
                                    style={{ backgroundColor: '#2dcd7a', color: '#000000' }}
                                    onClick={this.uploadParkMap}
                                >
                                    Upload Image
                                </Button>
                            </div>
                        </div>
                        :
                        <div style={{ width: '90%', marginLeft: '2%' }}>
                            <Typography style={{ fontSize: '1.3rem', marginBottom: '1rem', textAlign: 'left' }}>You have alread uploaded a site map</Typography>
                            <div style={{ display: 'flex', justifyContent: 'left' }}>
                                <Button style={{ backgroundColor: '#2dcd7a', color: '#000000' }} onClick={() => window.open(this.state.mapURL, '_blank')}>View Map</Button>
                                <Button style={{ backgroundColor: '#2dcd7a', color: '#000000', marginLeft: '2%' }} onClick={this.deleteMap}>Delete Map</Button>
                            </div>
                        </div>
                    }
                </ThemeProvider>
            </>
        );
    }
}

export default withStyles(useStyles)(SystemOptions);