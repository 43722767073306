import React, { Component } from 'react';
import { Grid, Button, Typography, Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import '../CSS/Archived.css';
import { getArchived, deleteArchives } from '../utils/OpenCampground_Database_Calls';
import SearchBar from "material-ui-search-bar";
import { withStyles } from '@material-ui/core/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import arch from '../assets/archived.png'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const useStyles = theme => ({
    circularProgress: {
        position: 'absolute',
        left: '55%',
        top: '50%',
        color: '#2dcd7a'
    }
});
const theme = createTheme({
    palette: {
        primary: {
            main: '#2dcd7a',
        },
    },
});
class Archived extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allArchived: [],
            archived: [],
            currentPage: 1,
            searchValue: '',
            all: true,
            cancelled: false,
            checkedOut: false,
            loading: true,
            noReservations: false,
            reservationToDelete: null,
        };
    }

    async componentDidMount() {
        await this.getArchived();
    }

    getArchived = async () => {
        let listOfArchived = await getArchived(this.props.user, this.props.serverName);
        this.setState({
            allArchived: listOfArchived,
            archived: listOfArchived,
            loading: false,
            noReservations: listOfArchived.length === 0
        });
    }

    getArchivedByReservationNumber = (value) => {
        const filteredReservations = this.state.allArchived.filter(archived =>
            archived.id.toString().includes(value)
        );
        this.setState({
            archived: filteredReservations,
            searchValue: value,
            loading: false,
            noReservations: filteredReservations.length === 0
        });
    }

    displayAllArchived = () => {
        this.setState({
            archived: this.state.allArchived,
            searchValue: '',
            noReservations: this.state.allArchived.length === 0
        });
    }

    filterClick = (type) => {
        let filteredReservations;
        if (type === 'checkedOut') {
            filteredReservations = this.state.allArchived.filter(archived => archived.cancelled === 0);
        } else if (type === 'cancelled') {
            filteredReservations = this.state.allArchived.filter(archived => archived.cancelled === 1);
        } else {
            filteredReservations = this.state.allArchived;
        }
        this.setState({ archived: filteredReservations });
    }

    handleCancel = () => {
        this.setState({ open: false });
        console.log("canceled");
    };

    handleConfirm = async () => {
        const { reservationToDelete } = this.state;
        this.setState({ open: false })
        let deleteresponse = await deleteArchives(this.props.user, this.props.serverName, reservationToDelete.id)
        this.setState({
            confirmationText: 'Reservation ' + reservationToDelete.id + ' has been permanently deleted from database',
            confirmationOpen: true
        })
        this.getArchived()
    };

    render() {
        const { classes } = this.props;
        const { loading, noReservations, archived } = this.state;

        if (loading) {
            return <CircularProgress className={classes.circularProgress} />;
        }

        const itemsPerPage = 15;
        const totalPages = Math.ceil(archived.length / itemsPerPage);
        let currentPage = this.state.currentPage;

        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = Math.min(startIndex + itemsPerPage, archived.length);

        const reservationsOnPage = archived.slice(startIndex, endIndex);

        let archivedRows = reservationsOnPage.map((item) => {
            return (
                <tr key={item.id} className='rows'>
                    <td>{item.id}</td>
                    <td>{item.first_name} {item.last_name}</td>
                    <td>{item.startdate}</td>
                    <td>{item.enddate}</td>
                    <td>{item.sitename}</td>
                    <td>${parseFloat(item.total).toFixed(2)}</td>
                    <td style={{
                        color: item.cancelled === 1 ? '#FB8100' : '#DB3737'
                    }}>
                        {item.cancelled === 1 ? "Cancelled" : "Checked Out"}
                    </td>
                </tr>
            );
        });

        const paginationButtons = [];
        const maxButtonsToShow = 10;
        let startPage, endPage;

        if (totalPages <= maxButtonsToShow) {
            startPage = 1;
            endPage = totalPages;
        } else {
            const maxPagesBeforeCurrentPage = Math.floor(maxButtonsToShow / 2);
            const maxPagesAfterCurrentPage = Math.ceil(maxButtonsToShow / 2) - 1;
            if (currentPage <= maxPagesBeforeCurrentPage) {
                startPage = 1;
                endPage = maxButtonsToShow;
            } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
                startPage = totalPages - maxButtonsToShow + 1;
                endPage = totalPages;
            } else {
                startPage = currentPage - maxPagesBeforeCurrentPage;
                endPage = currentPage + maxPagesAfterCurrentPage;
            }
        }

        for (let i = startPage; i <= endPage; i++) {
            paginationButtons.push(
                <Button className={`paginationButton ${currentPage === i ? "activePage" : ""}`} key={i} onClick={() => this.setState({ currentPage: i })}>
                    {i}
                </Button>
            );
        }

        const previousButton = (
            <Button className='previousButton' disabled={currentPage === 1} onClick={() => this.setState({ currentPage: currentPage - 1 })}>
                <ArrowBackIosIcon />
            </Button>
        );

        const nextButton = (
            <Button className='nextButton' disabled={currentPage === totalPages} onClick={() => this.setState({ currentPage: currentPage + 1 })}>
                <ArrowForwardIosIcon />
            </Button>
        );

        const lastButton = (
            <Button className='paginationButton' disabled={currentPage === totalPages} onClick={() => this.setState({ currentPage: totalPages })}>
                {totalPages}
            </Button>
        );

        return (
            <>
                <Grid className='archivedList'>
                    <Box className='infoBox'>
                        <Typography className='infoBarTypo'>Welcome: {this.props.serverName.charAt(0).toUpperCase() + this.props.serverName.slice(1)}</Typography>
                        <Typography className='infoBarTypo'>Username: {this.props.user}</Typography>
                    </Box>
                    <Box className='box'>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <img src={arch} alt="Archived" className='buttonImage' />
                            <Typography className='transactionstypography'>Archived Reservations</Typography>
                        </div>
                    </Box>
                    <Box className='box' style={{ marginTop: '-3%' }}>
                        <div className='buttonGroup' style={{ width: "50%" }}>
                            <Button style={{ whiteSpace: "nowrap" }} className='button' onClick={() => { this.setState({ showReservations: false, showCheckedInReservations: true }); this.filterClick('all'); }}>All</Button>
                            <Button style={{ whiteSpace: "nowrap" }} className='button' onClick={() => { this.setState({ showReservations: false, showCheckedInReservations: true }); this.filterClick('cancelled'); }}>Cancelled</Button>
                            <Button style={{ whiteSpace: "nowrap" }} className='button' onClick={() => { this.setState({ showCheckedInReservations: false, showReservations: true }); this.filterClick('checkedOut'); }}>Checked Out</Button>
                        </div>
                        <SearchBar
                            className='searchBox'
                            placeholder='Search By Reservation #'
                            value={this.state.searchValue}
                            onChange={(newValue) => this.setState({ searchValue: newValue })}
                            onRequestSearch={() => { this.setState({ loading: true }); this.getArchivedByReservationNumber(this.state.searchValue); }}
                            cancelOnEscape={true}
                            onCancelSearch={() => this.displayAllArchived()}
                        />
                    </Box>
                    <Box className='tableheadingbox'>
                        <Typography className='headerTypo'>Reservation #</Typography>
                        <Typography className='headerTypo'>Customer Name</Typography>
                        <Typography className='headerTypo'>Start Date</Typography>
                        <Typography className='headerTypo'>End Date</Typography>
                        <Typography className='headerTypo'>Site</Typography>
                        <Typography className='headerTypo'>Total</Typography>
                        <Typography className='headerTypo'>Reason</Typography>
                    </Box>
                    {!noReservations
                        ? <>
                            <Box className='tableBox'>
                                <table>
                                    <tbody>
                                        {archivedRows}
                                    </tbody>
                                </table>
                            </Box>
                            <div className="pagination">
                                {previousButton}
                                {paginationButtons}
                                ...
                                {lastButton}
                                {nextButton}
                            </div>
                        </>
                        : <Typography className='headerTypo'>No Reservations Found</Typography>
                    }
                </Grid>
            </>
        );
    }
};

export default withStyles(useStyles)(Archived);
