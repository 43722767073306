import AWS from 'aws-sdk';

const OPENCAMPGROUND_DATABASE_CALL_BASE_URL = " https://lzio69unu5.execute-api.us-east-2.amazonaws.com/dafault/opcDatabaseCalls"
AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: process.env.REACT_APP_AWS_REGION
    // accessKeyId: 'AKIA4WYY6H7CTYVR5MI6',
    // secretAccessKey: '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',
    // region: 'us-east-2'
});
const lambda = new AWS.Lambda();

//reservations
export const getReservations = async (user, serverName) => {
    const requestBody = {
        'operationName': 'getReservations',
        'payload': {
            "serverName": serverName,
            "user": user
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while retrieving reservations.', err);
    }
}

export const getTransactions = async (user, serverName) => {
    const requestBody = {
        'operationName': 'getTransactions',
        'payload': {
            "serverName": serverName,
            "user": user
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while retrieving transactions.', err);
    }
}

// Sites Rigs Discounts Extras
export const getSiteRigDiscountExtra = async (user, serverName) => {
    const requestBody = {
        'operationName': 'getSiteRigDiscountExtra',
        'payload': {
            "serverName": serverName,
            "user": user
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while retrieving getSiteRigDiscountExtra.', err);
    }
}

export const getSpaces = async (user, serverName, endDate, startDate) => {
    const requestBody = {
        'operationName': 'getSpaces',
        'payload': {
            "serverName": serverName,
            "endDate": endDate,
            "startDate": startDate,
            "user": user
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while retrieving getSiteRigDiscountExtra.', err);
    }
}

export const getTaxes = async (user, servername) => {
    const requestBody = {
        'operationName': 'getTaxes',
        'payload': {
            "serverName": servername,
            "user": user
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while retrieving taxes.', err);
    }
}

export const checkOut = async (user, serverName, reservation_id) => {
    const requestBody = {
        'operationName': 'checkOut',
        'payload': {
            "serverName": serverName,
            "reservation_id": reservation_id,
            "user": user
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while checking out customer.', err);
    }
}

export const checkIn = async (user, serverName, reservation_id) => {
    const requestBody = {
        'operationName': 'checkIn',
        'payload': {
            "serverName": serverName,
            "reservation_id": reservation_id,
            "user": user
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while checking In customer.', err);
    }
}

export const cancel = async (user, serverName, reservation_id) => {
    const requestBody = {
        'operationName': 'cancel',
        'payload': {
            "serverName": serverName,
            "reservation_id": reservation_id,
            "user": user
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while adding paymentType', err);
    }
}

export const makeReservation = async (user, serverName, lname, fname, address, city, state, zip, phone, email, rigTypeId, spaceId, discountID, startDate, endDate,
    deposit, slides, length, adults, pets, total, createdAt, taxAmount, remote, notes, oneTimeDiscount, camperId, seasonBooking) => {
    const requestBody = {
        'operationName': 'createReservation',
        'payload': {
            "user": user,
            "serverName": serverName,
            "lname": lname,
            "fname": fname,
            "address": address,
            "city": city,
            "state": state,
            "zip": zip,
            "phone": phone,
            "email": email,
            "rigTypeId": rigTypeId,
            "spaceId": spaceId,
            "discountID": discountID,
            "startDate": startDate,
            "endDate": endDate,
            "deposit": deposit,
            "slides": slides,
            "len": length,
            "adults": adults,
            "pets": pets,
            "total": total,
            "createdAt": createdAt,
            "taxAmount": taxAmount,
            "remote": remote,
            "notes": notes,
            "oneTimeDiscount": oneTimeDiscount,
            "camperId": camperId,
            "seasonBooking": seasonBooking
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        console.log("Payload: ", requestBody)
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while making reservation.', err);
    }
}

export const getCardType = async (cardType) => {
    if (cardType === 'Visa' || cardType === 'V') {
        return (3);
    } else if (cardType === 'MasterCard' || cardType === 'M') {
        return (5);
    } else if (cardType === 'Discover' || cardType === 'D') {
        return (4);
    } else if (cardType === 'American Express' || cardType === 'A') {
        return (6);
    }
}

export const recordPayment = async (user, serverName, amount, reservationNumber, createdAt, updatedAt, memo, ccId, last4, nameOnCard, pmtDate, refundable, token) => {
    const requestBody = {
        'operationName': 'recordPayment',
        'payload': {
            "serverName": serverName,
            "amount": amount,
            "reservationNumber": reservationNumber,
            "createdAt": createdAt,
            "updatedAt": updatedAt,
            "memo": memo,
            "ccId": ccId,
            "last4": last4,
            "nameOnCard": nameOnCard,
            "pmtDate": pmtDate,
            "refundable": refundable,
            "token": token,
            "user": user
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while making reservation.', err);
    }
}

export const getRemoteReservationsStatus = async (user, serverName) => {
    const requestBody = {
        'operationName': 'getRemoteReservationsStatus',
        'payload': {
            "serverName": serverName,
            "user": user
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while getting remote reservations status', err);
    }
}

export const updateRemoteReservationsStatus = async (user, serverName, useRemote, config, useSeasonal, seasonStart, seasonEnd) => {
    let allow, require;

    if (config === 'require') {
        allow = '0';
        require = '1';
    } else if (config === 'allow') {
        allow = '1';
        require = '0';
    } else if (config === 'doNotAllow') {
        allow = '0';
        require = '0';
    }
    const requestBody = {
        'operationName': 'updateRemoteReservationsStatus',
        'payload': {
            "serverName": serverName,
            "useRemote": useRemote,
            "allow": allow,
            "require": require,
            "user": user,
            "useSeasonal": useSeasonal,
            "seasonStart": seasonStart,
            "seasonEnd": seasonEnd,
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while updating remote reservations status', err);
    }
}

export const updateTransaction = async (user, serverName, id) => {
    const requestBody = {
        'operationName': 'updateTransaction',
        'payload': {
            "serverName": serverName,
            "id": id,
            "user": user
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while updating transaction', err);
    }
}

//cardConnect API
export const cardConnectPaymemt = async (user, merchantId, amount, cardNumber, expiryMonth, expiryYear, cvv, zipCode, address, name, city) => {
    const requestBody = {
        'operationName': 'cardConnectPaymemt',
        'payload': {
            "merchid": merchantId,
            "account": cardNumber,
            "expiry": expiryMonth.toString().padStart(2, '0') + expiryYear.toString(),
            "cvv2": cvv,
            "postal": zipCode,
            "amount": amount,
            "name": name,
            "address": address,
            "city": city,
            "user": user
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        return json;
    } catch (err) {
        console.error('Error while making Card Connect Payment', err);
    }
}

export const cardConnectInquire = async (user, merchantId, referenceNumber) => {
    const requestBody = {
        'operationName': 'cardConnectInquire',
        'payload': {
            "merchid": merchantId,
            "referenceNumber": referenceNumber,
            "user": user
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        return json;
    } catch (err) {
        console.error('Error while making Card Connect inquiry', err);
    }
}

export const cardConnectVoid = async (user, merchantId, referenceNumber) => {
    const requestBody = {
        'operationName': 'cardConnectVoid',
        'payload': {
            "merchid": merchantId,
            "retref": referenceNumber,
            "user": user
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        return json;
    } catch (err) {
        console.error('Error while making Card Connect inquiry', err);
    }
}

export const cardConnectRefund = async (user, merchantId, referenceNumber, amount) => {
    const requestBody = {
        'operationName': 'cardConnectRefund',
        'payload': {
            "merchid": merchantId,
            "retref": referenceNumber,
            "amount": amount,
            "user": user
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        return json;
    } catch (err) {
        console.error('Error while making Card Connect inquiry', err);
    }
}

export const cardConnectGetListOfTerminals = async (user, merchantId) => {
    const requestBody = {
        'operationName': 'cardConnectGetListOfTerminals',
        'payload': {
            "merchantId": merchantId,
            "user": user
        }
    };

    const params = {
        FunctionName: process.env.REACT_APP_AWS_FUNCTION_NAME,
        Payload: JSON.stringify(requestBody),
    };

    try {
        let data = await lambda.invoke(params).promise();
        let json = JSON.parse(data.Payload);
        if (data.StatusCode === 200) {
            return json;
        }
    } catch (err) {
        console.error('Error while retrieving reservations.', err);
    }
}

export const cardConnectConnectToTerminal = async (user, merchantId, hsn) => {
    const requestBody = {
        'operationName': 'cardConnectConnectToTerminal',
        'payload': {
            "hsn": hsn,
            "merchantId": merchantId,
            "force": true,
            "user": user
        }
    };

    const params = {
        FunctionName: process.env.REACT_APP_AWS_FUNCTION_NAME,
        Payload: JSON.stringify(requestBody),
    };

    try {
        let data = await lambda.invoke(params).promise();

        let json = JSON.parse(data.Payload);
        if (data.StatusCode === 200) {
            return json;
        }
    } catch (err) {
        console.error('Error while retrieving reservations.', err);
    }
}

export const cardConnectTerminalPayment = async (user, merchantId, hsn, amount, skey) => {
    const requestBody = {
        'operationName': 'cardConnectTerminalPayment',
        'payload': {
            "sessionKey": skey,
            "hsn": hsn,
            "merchantId": merchantId,
            "amount": amount,
            "user": user
        }
    };

    const params = {
        FunctionName: process.env.REACT_APP_AWS_FUNCTION_NAME,
        Payload: JSON.stringify(requestBody),
    };

    try {
        let data = await lambda.invoke(params).promise();
        let json = JSON.parse(data.Payload);
        if (data.StatusCode === 200) {
            return json;
        }
    } catch (err) {
        console.error('Error while retrieving reservations.', err);
    }
}

//settings API
export const deleteRecord = async (user, serverName, database, id) => {
    const requestBody = {
        'operationName': 'deleteRecord',
        'payload': {
            "serverName": serverName,
            "database": database,
            "id": id,
            "user": user
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while deleting', err);
    }
}

export const getSiteTypes = async (user, serverName) => {
    const requestBody = {
        'operationName': 'getSiteTypes',
        'payload': {
            "serverName": serverName,
            "user": user
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while getting site Types', err);
    }
}

export const updateSiteType = async (user, serverName, name, active, id) => {
    const requestBody = {
        'operationName': 'updateSiteTypes',
        'payload': {
            "serverName": serverName,
            "name": name,
            "active": active,
            "id": id,
            "user": user
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while editing site Types', err);
    }
}

export const getSeasons = async (user, serverName) => {
    const requestBody = {
        'operationName': 'getSeasons',
        'payload': {
            "serverName": serverName,
            "user": user
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while getting seasons', err);
    }
}

export const updateSeason = async (user, serverName, name, startDate, endDate, week, month, active, id) => {
    const requestBody = {
        'operationName': 'updateSeasons',
        'payload': {
            "serverName": serverName,
            "name": name,
            "startdate": startDate,
            "enddate": endDate,
            "weekly": week,
            "monthly": month,
            "active": active,
            "id": id,
            "user": user
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while editing seasons', err);
    }
}

export const getPrices = async (user, serverName) => {
    const requestBody = {
        'operationName': 'getPrices',
        'payload': {
            "serverName": serverName,
            "user": user
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while getting prices', err);
    }
}

export const getSettingSpaces = async (user, serverName) => {
    const requestBody = {
        'operationName': 'getSettingSpaces',
        'payload': {
            "serverName": serverName,
            "user": user
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while getting Spaces', err);
    }
}

export const updateSpace = async (user, serverName, name, power_15a, power_30a, power_50a, water, sewer, length, width, sitetype_id, price_id, remote_reservable, id, special_condition, active) => {
    const requestBody = {
        'operationName': 'updateSpace',
        'payload': {
            "user": user,
            "serverName": serverName,
            "name": name,
            "power_15a": power_15a,
            "power_30a": power_30a,
            "power_50a": power_50a,
            "water": water,
            "sewer": sewer,
            "len": length,
            "width": width,
            "sitetype_id": sitetype_id,
            "price_id": price_id,
            "remote_reservable": remote_reservable,
            "id": id,
            "special_condition": special_condition,
            "active": active
        }
    };
    console.log(requestBody)
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while editing spaces', err);
    }
}

export const getDiscount = async (user, serverName) => {
    const requestBody = {
        'operationName': 'getDiscount',
        'payload': {
            "serverName": serverName,
            "user": user
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while getting dicsount', err);
    }
}

export const updateDiscount = async (user, serverName, editingDiscountId, discountName, duration, discountPercent, amt, amtDay, amtWeek, amtMonth, sunday,
    monday, tuesday, wednesday, thursday, friday, saturday, daily, week, month, seasonal, showRemote, active) => {
    const requestBody = {
        'operationName': 'updateDiscount',
        'payload': {
            "user": user,
            "serverName": serverName,
            "id": editingDiscountId,
            "discountName": discountName,
            "duration": duration,
            "discountPercent": discountPercent,
            "amt": amt,
            "amtDay": amtDay,
            "amtWeek": amtWeek,
            "amtMonth": amtMonth,
            "sunday": sunday,
            "monday": monday,
            "tuesday": tuesday,
            "wednesday": wednesday,
            "thursday": thursday,
            "friday": friday,
            "saturday": saturday,
            "daily": daily,
            "week": week,
            "month": month,
            "seasonal": seasonal,
            "showRemote": showRemote,
            "active": active
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        console.log(response)
        let json = await response.json()
        console.log(json)
        if (json.statusCode === 200) {
            console.log(json.body)
            return json.body;
        }
    } catch (err) {
        console.error('Error while updating dicsount', err);
    }
}

export const getRigTypes = async (user, serverName) => {
    const requestBody = {
        'operationName': 'getRigTypes',
        'payload': {
            "serverName": serverName,
            "user": user
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while getting rig types', err);
    }
}

export const updateRigType = async (user, serverName, name, active, id) => {
    const requestBody = {
        'operationName': 'updateRigTypes',
        'payload': {
            "serverName": serverName,
            "name": name,
            "active": active,
            "id": id,
            "user": user
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while editing rig Types', err);
    }
}

export const getTax = async (user, serverName) => {
    const requestBody = {
        'operationName': 'getTax',
        'payload': {
            "serverName": serverName,
            "user": user
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while getting tax', err);
    }
}

export const updateTax = async (user, serverName, id, name, percent, amount, isPercent, daily, weekly, monthly, seasonal, active) => {
    const requestBody = {
        'operationName': 'updateTax',
        'payload': {
            "serverName": serverName,
            "id": id,
            "name": name,
            "percent": percent,
            "amount": amount,
            "isPercent": isPercent,
            "daily": daily,
            "weekly": weekly,
            "monthly": monthly,
            "seasonal": seasonal,
            "active": active,
            "user": user
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while updating tax', err);
    }
}

export const getExtras = async (user, serverName) => {
    const requestBody = {
        'operationName': 'getExtras',
        'payload': {
            "serverName": serverName,
            "user": user
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while getting extra', err);
    }
}

export const updateExtra = async (user, serverName, id, name, remote, remoteRequired, type, daily, week, month, rate, unit, charge, active) => {
    const requestBody = {
        'operationName': 'updateExtra',
        'payload': {
            "serverName": serverName,
            "id": id,
            "name": name,
            "remote": remote,
            "remoteRequired": remoteRequired,
            "type": type,
            "daily": daily,
            "weekly": week,
            "monthly": month,
            "rate": rate,
            "unit": unit,
            "charge": charge,
            "active": active,
            "user": user
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while updating extra', err);
    }
}

export const getEmails = async (user, serverName) => {
    const requestBody = {
        'operationName': 'getEmails',
        'payload': {
            "serverName": serverName,
            "user": user
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body[0];
        }
    } catch (err) {
        console.error('Error while getting mail settings', err);
    }
}

export const getEmailTemplates = async (user, serverName) => {
    const requestBody = {
        'operationName': 'getEmailTemplates',
        'payload': {
            "serverName": serverName,
            "user": user
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while getting mail templates', err);
    }
}

export const updateMailMessages = async (user, serverName, id, body) => {
    const requestBody = {
        'operationName': 'updateMailMessages',
        'payload': {
            "serverName": serverName,
            "id": id,
            "body": body,
            "user": user
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while editing mail templates', err);
    }
}

export const createNewSiteType = async (user, serverName, siteType) => {
    const requestBody = {
        'operationName': 'createNewSiteType',
        'payload': {
            "serverName": serverName,
            "siteType": siteType,
            "user": user
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while adding siteType', err);
    }
}

export const createNewSeason = async (user, serverName, seasonName, startDate, endDate, week, month) => {
    const requestBody = {
        'operationName': 'createNewSeason',
        'payload': {
            "serverName": serverName,
            "name": seasonName,
            "startDate": startDate,
            "endDate": endDate,
            "appliesToWeek": week,
            "appliesToMonth": month,
            "user": user
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while adding new season', err);
    }
}

export const createNewSpace = async (user, serverName, name, e15a, e30a, e50a, water, sewer, len, width, sitetype_id, price_id, remote, special_condition, active) => {
    const requestBody = {
        'operationName': 'createNewSpace',
        'payload': {
            "serverName": serverName,
            "user": user,
            "name": name,
            "power15a": e15a,
            "power30a": e30a,
            "power50a": e50a,
            "water": water,
            "sewer": sewer,
            "len": len,
            "width": width,
            "sitetype_id": sitetype_id,
            "price_id": price_id,
            "remote_reservable": remote,
            "special_condition": special_condition,
            "active": active
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        console.log(requestBody)
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while adding new space', err);
    }
}

export const getSitePrice = async (user, serverName) => {
    const requestBody = {
        'operationName': 'getSitePrice',
        'payload': {
            "serverName": serverName,
            "user": user
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while getting getSitePrice', err);
    }
}

export const createNewDiscount = async (user, serverName, discountname, duration, sunday, monday, tuesday, wednesday, thursday, friday, saturday, discountPpercent, applDaily, applWeek, applMonth, applSeason, amt, amtDaily, amtWeek, amtMonth) => {
    const requestBody = {
        'operationName': 'createNewDiscount',
        'payload': {
            "serverName": serverName,
            "user": user,
            "name": discountname,
            "duration": duration,
            "sunday": sunday,
            "monday": monday,
            "tuesday": tuesday,
            "wednesday": wednesday,
            "thursday": thursday,
            "friday": friday,
            "saturday": saturday,
            "discount_percent": discountPpercent,
            "disc_appl_daily": applDaily,
            "disc_appl_week": applWeek,
            "disc_appl_month": applMonth,
            "disc_appl_seasonal": applSeason,
            "amount": amt,
            "amount_daily": amtDaily,
            "amount_weekly": amtWeek,
            "amount_monthly": amtMonth
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while adding new discount', err);
    }
}

export const createNewRigType = async (user, serverName, rigType) => {
    const requestBody = {
        'operationName': 'createNewRigType',
        'payload': {
            "serverName": serverName,
            "rigType": rigType,
            "user": user
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while adding rigtype', err);
    }
}

export const createNewTax = async (user, serverName, name, is_percent, percent, amount, apl_daily, apl_week, apl_month, apl_seasonal) => {
    const requestBody = {
        'operationName': 'createNewTax',
        'payload': {
            "serverName": serverName,
            "name": name,
            "is_percent": is_percent,
            "percent": percent,
            "amount": amount,
            "apl_daily": apl_daily,
            "apl_week": apl_week,
            "apl_month": apl_month,
            "apl_seasonal": apl_seasonal,
            "user": user
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while adding tax', err);
    }
}

export const createNewExtra = async (user, serverName, name, daily, weekly, monthly, charge, rate, unit_name, extra_type, remote_display, remote_required) => {
    let extraType;
    if (extra_type === 'standard') {
        extraType = 0
    } else if (extra_type === 'counted') {
        extraType = 1
    } else if (extra_type === 'metered') {
        extraType = 2
    } else if (extra_type === 'occasional') {
        extraType = 3
    } else {
        extraType = 4
    }
    const requestBody = {
        'operationName': 'createNewExtra',
        'payload': {
            "serverName": serverName,
            "user": user,
            "name": name,
            "daily": daily,
            "weekly": weekly,
            "monthly": monthly,
            "charge": charge,
            "rate": rate,
            "unit_name": unit_name,
            "extra_type": extraType,
            "remote_display": remote_display,
            "remote_required": remote_required
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while adding Extra', err);
    }
}

export const camperDetailsForReservation = async (user, serverName, reservation_id) => {
    const requestBody = {
        'operationName': 'camperDetailsForReservation',
        'payload': {
            "serverName": serverName,
            "reservation_id": reservation_id,
            "user": user
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body[0];
        }
    } catch (err) {
        console.error('Error while getting camper details', err);
    }
}

export const spaceSpecificRates = async (user, serverName, startDate, endDate, spaceName) => {
    const requestBody = {
        'operationName': 'spaceSpecificRates',
        'payload': {
            "serverName": serverName,
            "startDate": startDate,
            "endDate": endDate,
            "spaceName": spaceName,
            "user": user
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body[0];
        }
    } catch (err) {
        console.error('Error while getting space specific rates', err);
    }
}

export const getPayments = async (user, serverName, reservation_id) => {
    const requestBody = {
        'operationName': 'getPayments',
        'payload': {
            "serverName": serverName,
            "reservation_id": reservation_id,
            "user": user
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while getting payment', err);
    }
}

export const confirmReservation = async (user, serverName, reservation_id) => {
    const requestBody = {
        'operationName': 'confirmReservation',
        'payload': {
            "serverName": serverName,
            "reservation_id": reservation_id,
            "user": user
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while confirming remote reservation', err);
    }
}

export const getArchived = async (user, serverName) => {
    const requestBody = {
        'operationName': 'getArchived',
        'payload': {
            "serverName": serverName,
            "user": user
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while getting archived', err);
    }
}

export const addVariableCharge = async (user, serverName, reservation_id, detail, amount, created_at, updated_at) => {
    const requestBody = {
        'operationName': 'addVariableCharge',
        'payload': {
            "serverName": serverName,
            "reservation_id": reservation_id,
            "detail": detail,
            "amount": amount,
            "created_at": created_at,
            "updated_at": updated_at,
            "user": user
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()

        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while adding variable charge', err);
    }
}

export const login = async (serverName, userName, password) => {
    const requestBody = {
        'operationName': 'login',
        'payload': {
            "serverName": serverName,
            "userName": userName,
            "password": password
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        return json;

    } catch (err) {
        console.error('Error while logging in', err);
    }
}

export const getCampers = async (user, serverName) => {
    const requestBody = {
        'operationName': 'getCampers',
        'payload': {
            "serverName": serverName,
            "user": user
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        if (json.statusCode === 200) {
            return json.body;
        }

    } catch (err) {
        console.error('Error while getting campers', err);
    }
}

export const getUsers = async (user, serverName) => {
    const requestBody = {
        'operationName': 'getUsers',
        'payload': {
            "serverName": serverName,
            "user": user
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        if (json.statusCode === 200) {
            return json.body;
        }

    } catch (err) {
        console.error('Error while getting users', err);
    }
}

export const updateUser = async (user, serverName, admin, id) => {
    const requestBody = {
        'operationName': 'updateUser',
        'payload': {
            "serverName": serverName,
            "admin": admin,
            "id": id,
            "user": user
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        if (json.statusCode === 200) {
            return json.body;
        }

    } catch (err) {
        console.error('Error while updating users', err);
    }
}

export const addUser = async (user, serverName, userName, password, admin) => {
    const requestBody = {
        'operationName': 'addUser',
        'payload': {
            "serverName": serverName,
            "userName": userName,
            "password": password,
            "admin": admin,
            "user": user
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        if (json.statusCode === 200) {
            return json.body;
        }

    } catch (err) {
        console.error('Error while adding new user', err);
    }
}

export const addExtras = async (user, serverName, extra_id, reservation_id, number, days, charge, created_at) => {
    const requestBody = {
        'operationName': 'addExtras',
        'payload': {
            "serverName": serverName,
            "extra_id": extra_id,
            "reservation_id": reservation_id,
            "number": number,
            "days": days,
            "charge": charge,
            "created_at": created_at,
            "user": user
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        if (json.statusCode === 200) {
            return json.body;
        }

    } catch (err) {
        console.error('Error while adding extra', err);
    }
}

export const getIntegrations = async (user, serverName) => {
    const requestBody = {
        'operationName': 'getIntegrations',
        'payload': {
            "serverName": serverName,
            "user": user
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        if (json.statusCode === 200) {
            return json.body[0];
        }

    } catch (err) {
        console.error('Error while getting integrations', err);
    }
}

export const getExtrasAndVariableCharges = async (user, serverName, reservation_id) => {
    const requestBody = {
        'operationName': 'getExtrasAndVariableCharges',
        'payload': {
            "serverName": serverName,
            "reservation_id": reservation_id,
            "user": user
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        if (json.statusCode === 200) {
            return json.body;
        }

    } catch (err) {
        console.error('Error while getting extra and variable charges', err);
    }
}

export const changeIntegration = async (user, serverName, integration_name) => {
    const requestBody = {
        'operationName': 'changeIntegration',
        'payload': {
            "serverName": serverName,
            "integration_name": integration_name,
            "user": user
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        if (json.statusCode === 200) {
            return json.body;
        }

    } catch (err) {
        console.error('Error while changing integration', err);
    }
}

export const changeEmails = async (user, serverName, sender, reply, cc, bcc) => {
    const requestBody = {
        'operationName': 'changeEmails',
        'payload': {
            "serverName": serverName,
            "sender": sender,
            "reply": reply,
            "cc": cc,
            "bcc": bcc,
            "user": user
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        if (json.statusCode === 200) {
            return json.body;
        }

    } catch (err) {
        console.error('Error while changing emails', err);
    }
}

export const sendMail = async (user, serverName, from, to, subject, body, reply, cc, bcc) => {
    const requestBody = {
        'operationName': 'sendMail',
        'payload': {
            "serverName": serverName,
            "from": from,
            "to": to,
            "subject": subject,
            "body": body,
            "reply": reply,
            "cc": cc,
            "bcc": bcc,
            "user": user
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        if (json.statusCode === 200) {
            return json.body;
        }

    } catch (err) {
        console.error('Error while changing emails', err);
    }
}

export const deleteArchives = async (user, serverName, reservation_id) => {
    const requestBody = {
        'operationName': 'deleteArchives',
        'payload': {
            "serverName": serverName,
            "reservation_id": reservation_id,
            "user": user
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        if (json.statusCode === 200) {
            return json.body;
        }

    } catch (err) {
        console.error('Error while deleting archived reservation', err);
    }
}

export const getSuspendedStatus = async (serverName) => {
    const requestBody = {
        'operationName': 'getSuspendedStatus',
        'payload': {
            "serverName": serverName
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        return json;

    } catch (err) {
        console.error('Error while getting suspended status', err);
    }
}

export const deleteAddedExtra = async (user, serverName, id) => {
    const requestBody = {
        'operationName': 'deleteAddedExtra',
        'payload': {
            "serverName": serverName,
            "id": id,
            "user": user
        }
    };
    console.log(requestBody)
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        return json;

    } catch (err) {
        console.error('Error while deleting added extra', err);
    }
}

export const getSiteAvailabilityData = async (user, serverName, date, start, end) => {
    const requestBody = {
        'operationName': 'getSiteAvailabilityData',
        'payload': {
            "serverName": serverName,
            "date": date,
            "start": start,
            "end": end,
            "user": user
        }
    };
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        return json;

    } catch (err) {
        console.error('Error while getting site availability data', err);
    }
}

export const getArrivingTodayOrTomorrow = async (user, serverName, date) => {
    const requestBody = {
        'operationName': 'getArrivingTodayOrTomorrow',
        'payload': {
            "serverName": serverName,
            "date": date,
            "user": user
        }
    };
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        return json;

    } catch (err) {
        console.error('Error while getting  arriving today and tomorrow', err);
    }
}

export const getSceduledArrivals = async (user, serverName, date1, date2) => {
    const requestBody = {
        'operationName': 'getSceduledArrivals',
        'payload': {
            "serverName": serverName,
            "date1": date1,
            "date2": date2,
            "user": user
        }
    };
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        return json;

    } catch (err) {
        console.error('Error while getting scheduled arrivals', err);
    }
}

export const getCheckingOutTodatOrTomorrow = async (user, serverName, date) => {
    const requestBody = {
        'operationName': 'getCheckingOutTodatOrTomorrow',
        'payload': {
            "serverName": serverName,
            "date": date,
            "user": user
        }
    };
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        return json;

    } catch (err) {
        console.error('Error while getting checkout today and tomorrow', err);
    }
}

export const getScheduledCheckOuts = async (user, serverName, date1, date2) => {
    const requestBody = {
        'operationName': 'getScheduledCheckOuts',
        'payload': {
            "serverName": serverName,
            "date1": date1,
            "date2": date2,
            "user": user
        }
    };
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        return json;

    } catch (err) {
        console.error('Error while getting scheduled checkouts', err);
    }
}

export const getInParkList = async (user, serverName) => {
    const requestBody = {
        'operationName': 'getInParkList',
        'payload': {
            "serverName": serverName,
            "user": user
        }
    };
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        return json;

    } catch (err) {
        console.error('Error while getting in park list', err);
    }
}

export const getReservationsReport = async (user, serverName, date1, date2) => {
    const requestBody = {
        'operationName': 'getReservationsReport',
        'payload': {
            "serverName": serverName,
            "date1": date1,
            "date2": date2,
            "user": user
        }
    };
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        return json;

    } catch (err) {
        console.error('Error while getting scheduled reservations', err);
    }
}

export const getPaymentsReport = async (user, serverName, date1, date2) => {
    const requestBody = {
        'operationName': 'getPaymentsReport',
        'payload': {
            "serverName": serverName,
            "date1": date1,
            "date2": date2,
            "user": user,
        }
    };
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        return json;

    } catch (err) {
        console.error('Error while getting scheduled reservations', err);
    }
}

export const getUserAddedStatus = async (serverName, emailId) => {
    const requestBody = {
        'operationName': 'getUserAddedStatus',
        'payload': {
            "serverName": serverName,
            "emailId": emailId
        }
    };
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        return json.body[0];

    } catch (err) {
        console.error('Error while getting scheduled reservations', err);
    }
}

export const changePassword = async (serverName, userId, password) => {
    const requestBody = {
        'operationName': 'changePassword',
        'payload': {
            "serverName": serverName,
            "userId": userId,
            "password": password
        }
    };
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        return json;

    } catch (err) {
        console.error('Error while getting scheduled reservations', err);
    }
}

export const getBlackoutDates = async (user, serverName) => {
    const requestBody = {
        'operationName': 'getBlackoutDates',
        'payload': {
            "serverName": serverName,
            "user": user
        }
    };
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        return json.body;
    } catch (err) {
        console.error('Error while getting blackout dates', err);
    }
}

export const createNewBlackout = async (user, serverName, name, start, end, active) => {
    const requestBody = {
        'operationName': 'createNewBlackout',
        'payload': {
            "serverName": serverName,
            "name": name,
            "start": start,
            "end": end,
            "active": active,
            "user": user
        }
    };
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        return json.body;
    } catch (err) {
        console.error('Error while creating new blackout dates', err);
    }
}

export const updateBlackout = async (user, serverName, name, start, end, active, id) => {
    const requestBody = {
        'operationName': 'updateBlackout',
        'payload': {
            "serverName": serverName,
            "name": name,
            "start": start,
            "end": end,
            "active": active,
            "id": id,
            "user": user
        }
    };
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        return json.body;
    } catch (err) {
        console.error('Error while updating blackout dates', err);
    }
}

export const getRemoteStatusIntegrationBlackout = async (serverName) => {
    const requestBody = {
        'operationName': 'getRemoteStatusIntegrationBlackout',
        'payload': {
            "serverName": serverName
        }
    };
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        return json.body;
    } catch (err) {
        console.error('Error while getting RemoteStatus Integration Blackout', err);
    }
}

export const getRemoteExplainations = async (user, serverName) => {
    const requestBody = {
        'operationName': 'getRemoteExplainations',
        'payload': {
            "serverName": serverName,
            "user": user
        }
    };
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        return json.body[0];
    } catch (err) {
        console.error('Error while getting remote explainations', err);
    }
}

export const updateRemoteExplainations = async (user, serverName, facilities, additionalDetails, rules, changes, cancellations, addressLine1, addressLine2, addressLine3, id) => {
    const requestBody = {
        'operationName': 'updateRemoteExplainations',
        'payload': {
            "serverName": serverName,
            "facilities": facilities,
            "additionalDetails": additionalDetails,
            "rules": rules,
            "changes": changes,
            "cancellations": cancellations,
            "addressLine1": addressLine1,
            "addressLine2": addressLine2,
            "addressLine3": addressLine3,
            "id": id,
            "user": user
        }
    };
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        console.log(requestBody)
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        return json.body;
    } catch (err) {
        console.error('Error while updating remote explainations', err);
    }
}

export const getGroups = async (user, serverName) => {
    const requestBody = {
        'operationName': 'getGroups',
        'payload': {
            "serverName": serverName,
            "user": user
        }
    };
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        return json;

    } catch (err) {
        console.error('Error while getting groups', err);
    }
}

export const getGroupCampers = async (user, serverName, id) => {
    const requestBody = {
        'operationName': 'getGroupCampers',
        'payload': {
            "serverName": serverName,
            "id": id,
            "user": user
        }
    };
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        return json;

    } catch (err) {
        console.error('Error while getting group campers', err);
    }
}

export const customerDetailChanges = async (user, serverName, fname, lname, emailId, phoneNumber, address, city, state, zip, camperId) => {
    const requestBody = {
        'operationName': 'customerDetailChanges',
        'payload': {
            "serverName": serverName,
            "fname": fname,
            "lname": lname,
            "email": emailId,
            "phoneNumber": phoneNumber,
            "address": address,
            "city": city,
            "state": state,
            "zip": zip,
            "camperId": camperId,
            "user": user
        }
    };
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        return json;

    } catch (err) {
        console.error('Error while updating camper details', err);
    }
}

export const internalNotesChanges = async (user, serverName, note, reservationId) => {
    const requestBody = {
        'operationName': 'internalNotesChanges',
        'payload': {
            "serverName": serverName,
            "note": note,
            "reservationId": reservationId,
            "user": user
        }
    };
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        return json;

    } catch (err) {
        console.error('Error while updating internal notes', err);
    }
}

export const getGuestDetails = async (user, serverName, camperId) => {
    const requestBody = {
        'operationName': 'getGuestDetails',
        'payload': {
            "serverName": serverName,
            "camperId": camperId,
            "user": user
        }
    };
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        return json;

    } catch (err) {
        console.error('Error while getting camper details', err);
    }
}

export const vehicleDetailChange = async (user, serverName, vstate, vlicense, vlength, vslides, reservationId) => {
    const requestBody = {
        'operationName': 'vehicleDetailChange',
        'payload': {
            "serverName": serverName,
            "vstate": vstate,
            "vlicense": vlicense,
            "vlength": vlength,
            "vslides": vslides,
            "reservationId": reservationId,
            "user": user
        }
    };
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        return json;

    } catch (err) {
        console.error('Error while canging guest vehicle details', err);
    }
}

export const guestPrivateNoteChange = async (user, serverName, note, camperId) => {
    const requestBody = {
        'operationName': 'guestPrivateNoteChange',
        'payload': {
            "serverName": serverName,
            "note": note,
            "camperId": camperId,
            "user": user
        }
    };
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        return json;

    } catch (err) {
        console.error('Error while updating guest internal notes', err);
    }
}

export const occupancyReport = async (user, serverName, startDate, endDate) => {
    const requestBody = {
        'operationName': 'occupancyReport',
        'payload': {
            "serverName": serverName,
            "startDate": startDate,
            "endDate": endDate,
            "user": user
        }
    };
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        return json;

    } catch (err) {
        console.error('Error while getting occupancy report', err);
    }
}

export const extraReport = async (user, serverName, startDate, endDate, type) => {
    const requestBody = {
        'operationName': 'extraReport',
        'payload': {
            "serverName": serverName,
            "startDate": startDate,
            "endDate": endDate,
            "type": type,
            "user": user
        }
    };
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        return json;

    } catch (err) {
        console.error('Error while getting extra report', err);
    }
}

export const createnewCamper = async (user, serverName, fname, lname, email, phone, address, city, state, zip) => {
    const requestBody = {
        'operationName': 'createnewCamper',
        'payload': {
            "serverName": serverName,
            "fname": fname,
            "lname": lname,
            "email": email,
            "phone": phone,
            "address": address,
            "city": city,
            "state": state,
            "zip": zip,
            "user": user
        }
    };
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        return json;

    } catch (err) {
        console.error('Error while creating a new camper', err);
    }
}

export const createNewGroup = async (user, serverName, groupname, start, end, camperId, numberOfBookings) => {
    const requestBody = {
        'operationName': 'createNewGroup',
        'payload': {
            "serverName": serverName,
            "groupname": groupname,
            "start": start,
            "end": end,
            "camperId": camperId,
            "numberOfBookings": numberOfBookings,
            "user": user
        }
    };
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        return json;

    } catch (err) {
        console.error('Error while creating new group', err);
    }
}

export const createGroupReservations = async (user, serverName, lname, fname, address, city, state, zip, phone, email,
    groupId, spaceId, startDate, endDate, adults, pets, kids, total, createdAt, taxAmount, camperId, numberOfBookings) => {
    const requestBody = {
        'operationName': 'createGroupReservations',
        'payload': {
            "serverName": serverName,
            "lname": lname,
            "fname": fname,
            "address": address,
            "city": city,
            "state": state,
            "zip": zip,
            "phone": phone,
            "email": email,
            "groupId": groupId,
            "spaceId": spaceId,
            "startDate": startDate,
            "endDate": endDate,
            "adults": adults,
            "pets": pets,
            "kids": kids,
            "total": total,
            "createdAt": createdAt,
            "taxAmount": taxAmount,
            "camperId": camperId,
            "numberOfBookings": numberOfBookings,
            "user": user
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        console.log("Payload: ", requestBody)
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        if (json.statusCode === 200) {
            return json.body;
        }
    } catch (err) {
        console.error('Error while making group reservation.', err);
    }
}

export const saveParkMap = async (user, serverName, imageFile) => {
    const reader = new FileReader();
    reader.readAsDataURL(imageFile);

    return new Promise((resolve, reject) => {
        reader.onloadend = async () => {
            const base64Image = reader.result.split(',')[1];

            const payload = {
                operationName: "saveParkMap",
                serverName: serverName,
                imageFile: base64Image,
            };

            const params = {
                FunctionName: process.env.REACT_APP_AWS_FUNCTION_NAME,
                // FunctionName: 'OpenCampground_DatabaseConnections',
                Payload: JSON.stringify(payload),
            };

            try {
                const data = await lambda.invoke(params).promise();
                const json = JSON.parse(data.Payload);
                resolve(json)
            } catch (err) {
                console.error('Error while invoking Lambda:', err);
                reject(err);
            }
        };

        reader.onerror = (err) => {
            console.error('Error reading file:', err);
            reject(err);
        };
    });
};

export const getParkMapURL = async (user, serverName) => {
    const requestBody = {
        'operationName': 'getParkMapURL',
        'payload': {
            "serverName": serverName,
            "user": user
        }
    };
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        return json;

    } catch (err) {
        console.error('Error while getting park map', err);
    }
}

export const deleteParkMap = async (user, serverName, fileName) => {
    const requestBody = {
        'operationName': 'deleteParkMap',
        'payload': {
            "serverName": serverName,
            "fileName": fileName,
            "user": user
        }
    };
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        return json;

    } catch (err) {
        console.error('Error while deleting park map', err);
    }
}

export const changeSpace = async (user, serverName, spaceId, reservationId, editedStartDate, editedEndDate) => {
    const requestBody = {
        'operationName': 'changeSpace',
        'payload': {
            "serverName": serverName,
            "spaceId": spaceId,
            "reservationId": reservationId,
            "user": user,
            "editedStartDate": editedStartDate,
            "editedEndDate": editedEndDate
        }
    };
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        return json;

    } catch (err) {
        console.error('Error while changing space', err);
    }
}

export const deletePrices = async (user, serverName, id) => {
    const requestBody = {
        'operationName': 'deletePrices',
        'payload': {
            "serverName": serverName,
            "id": id,
            "user": user
        }
    };
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        return json;

    } catch (err) {
        console.error('Error while deleting price', err);
    }
}

export const updatePrices = async (user, serverName, daily, weekly, monthly, id, priceId, priceName, seasonal) => {
    const requestBody = {
        'operationName': 'updatePrices',
        'payload': {
            "user": user,
            "serverName": serverName,
            "daily": daily,
            "weekly": weekly,
            "monthly": monthly,
            "id": id,
            "priceId": priceId,
            "priceName": priceName,
            "seasonal": seasonal
        }
    };
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        return json;

    } catch (err) {
        console.error('Error while updating price', err);
    }
}

export const createPrices = async (user, serverName, priceName, daily, weekly, monthly, seasonal) => {
    const requestBody = {
        'operationName': 'createPrices',
        'payload': {
            "user": user,
            "serverName": serverName,
            "priceName": priceName,
            "daily": daily,
            "weekly": weekly,
            "monthly": monthly,
            "seasonal": seasonal
        }
    };
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        return json;

    } catch (err) {
        console.error('Error while adding new price', err);
    }
}

export const getCompanyName = async (user, serverName) => {
    const requestBody = {
        'operationName': 'getCompanyName',
        'payload': {
            "user": user,
            "serverName": serverName,
        }
    };
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        return json;

    } catch (err) {
        console.error('Error while getting server name', err);
    }
}

export const deleteSeason = async (user, serverName, id) => {
    const requestBody = {
        'operationName': 'deleteSeason',
        'payload': {
            "user": user,
            "serverName": serverName,
            "id": id
        }
    };
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(OPENCAMPGROUND_DATABASE_CALL_BASE_URL, options);
        let json = await response.json()
        return json;

    } catch (err) {
        console.error('Error while deleting season', err);
    }
}