import React, { Component } from 'react';
import {
    Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Checkbox, FormControlLabel,
    Dialog, DialogActions, DialogContent, DialogTitle
} from '@mui/material';
import '../CSS/Settings.css';
import { deleteRecord, getSiteTypes, createNewSiteType, updateSiteType } from '../utils/OpenCampground_Database_Calls';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { withStyles } from '@material-ui/core/styles';

const useStyles = theme => ({
    dialogPaper: {
        padding: theme.spacing(2),
        borderRadius: '10px',
    }
});

const theme = createTheme({
    palette: {
        primary: {
            main: '#2dcd7a',
        },
    },
});

class SiteTypes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            siteTypes: [],
            siteTypesUpdate: false,
            addNew: false,
            siteType: '',
            formValid: false,
            editingIndex: -1,
            editingSiteType: null,
            editingActive: false,
            open: false,
            popupTitle: '',
            popupText: '',
            deleteId: '',
            confirmOpen: false,
            confirmPopupTitle: 'Delete Site Type',
            ConfirmPopupText: 'Please make sure that the site type that you are deleting is not associated with any of the current or future reservations.'
        };
    }

    async componentDidMount() {
        this.siteTypes();
    }

    handleChange = (event) => {
        this.setState({
            editingSiteType: { ...this.state.editingSiteType, name: event.target.value }
        }, () => {
            this.formValidation();
        });
    }

    formValidation = () => {
        if (this.state.editingSiteType.name) {
            this.setState({
                formValid: true
            });
        } else if (this.state.formValid) {
            this.setState({
                formValid: false
            });
        }
    }

    deleteRecord = async (data) => {
        let deleteResponse = await deleteRecord(this.props.user, this.props.serverName, "sitetypes", data);
        this.setState({
            siteTypesUpdate: true,
            open: true,
            popupTitle: 'Delete Site Type',
            popupText: 'Site type deleted successfully.'
        });
        this.siteTypes();
    }

    siteTypes = async () => {
        if (this.state.siteTypesUpdate) {
            let getSiteTypesData = await getSiteTypes(this.props.user, this.props.serverName)
            this.setState({
                siteTypes: getSiteTypesData
            })
        } else {
            if (this.state.siteTypes.length > 0) {
            } else {
                let getSiteTypesData = await getSiteTypes(this.props.user, this.props.serverName)
                this.setState({
                    siteTypes: getSiteTypesData
                })
            }
        }
    }

    addNew = async () => {
        let siteExists = this.state.siteTypes.some(site => site.name.trim().toLowerCase() === this.state.editingSiteType.name.trim().toLowerCase());
        if (siteExists) {
            this.setState({
                open: true,
                popupText: 'This site type name already exists. Please choose a different name.'
            })
            return;
        }
        let createNewSiteTypeResponse = await createNewSiteType(this.props.user, this.props.serverName, this.state.editingSiteType.name);
        this.setState({
            siteTypesUpdate: true,
            addNew: false,
            editingSiteType: null,
            popupTitle: 'New Site Type',
            popupText: 'New site type added successfully.',
            open: true
        });
        this.siteTypes();
    }

    editSiteType = (index, siteType) => {
        this.setState({
            editingIndex: index,
            editingSiteType: siteType,
            editingActive: siteType.active ? true : false,
        });
    }

    handleEditChange = (event) => {
        this.setState({
            editingSiteType: { ...this.state.editingSiteType, name: event.target.value }
        });
    }

    handleEditActiveChange = (event) => {
        this.setState({
            editingActive: event.target.checked
        });
    }

    saveEdit = async () => {
        await updateSiteType(this.props.user, this.props.serverName, this.state.editingSiteType.name, this.state.editingActive ? 1 : 0, this.state.editingSiteType.id);
        this.setState({
            editingIndex: -1,
            siteTypesUpdate: true,
            editingSiteType: null,
            open: true,
            popupTitle: 'Edit Site Type',
            popupText: 'Site type updated successfully.'
        });
        this.siteTypes();
    }

    handleCancel = () => {
        this.setState({ open: false });
    };

    render() {
        const { classes } = this.props;
        return (
            <>
                <ThemeProvider theme={theme}>
                    <Dialog open={this.state.confirmOpen} onClick={() => this.setState({ confirmOpen: false })} classes={{ paper: classes.dialogPaper }}>
                        <DialogTitle>{this.state.confirmPopupTitle}</DialogTitle>
                        <DialogContent>
                            {this.state.ConfirmPopupText}
                        </DialogContent>
                        <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Button onClick={() => this.setState({ confirmOpen: false })} style={{ backgroundColor: '#FDECEB', color: '#DB3737', padding: '2% 10% 2% 10%', margin: '0 0 0 2%', borderRadius: '10px', marginTop: '3%' }}>
                                Cancel
                            </Button>
                            <Button onClick={() => this.deleteRecord(this.state.deleteId)} style={{ backgroundColor: '#2dcd7a', color: '#000000', padding: '2% 10% 2% 10%', margin: '0 0 0 2%', borderRadius: '10px', marginTop: '3%' }}>
                                Confirm
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={this.state.open} onClose={this.handleCancel} classes={{ paper: classes.dialogPaper }}>
                        <DialogTitle>{this.state.popupTitle}</DialogTitle>
                        <DialogContent>
                            {this.state.popupText}
                        </DialogContent>
                        <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Button onClick={this.handleCancel} style={{ backgroundColor: '#FDECEB', color: '#DB3737', padding: '2% 10% 2% 10%', margin: '0 0 0 2%', borderRadius: '10px', marginTop: '3%' }}>
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Button className='resButton' onClick={() => { this.setState({ addNew: true }) }} style={{ backgroundColor: '#2dcd7a', color: '#000000', marginBottom: '2%', textTransform: "capitalize" }}>Add New SiteType</Button>
                    {this.state.addNew || this.state.editingIndex >= 0
                        ?
                        <Dialog open={this.state.addNew || this.state.editingIndex >= 0} onClose={() => {
                            this.setState({
                                addNew: false, editingIndex: -1,
                                editingSiteType: null,
                                editingActive: false,
                            });
                        }} maxWidth="sm" fullWidth>
                            <DialogContent dividers>
                                <div>
                                    <h2 style={{ margin: "0px", marginBottom: "26px" }}>{this.state.editingIndex >= 0 ? "Update Site Type" : "Add Site Type"}</h2>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '4%' }}>
                                    <TextField id="siteType" required label="SiteType" value={this.state?.editingSiteType?.name}
                                        onChange={this.handleChange} />
                                    {this.state.editingIndex >= 0 ?
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={this.state.editingActive}
                                                    onChange={this.handleEditActiveChange}
                                                />
                                            }
                                            label="Active"
                                        /> : ""}
                                    <Button style={{ backgroundColor: '#2dcd7a', color: '#000000' }} onClick={this.state.editingIndex >= 0 ? this.saveEdit : this.addNew} disabled={this.state.editingIndex >= 0 ? false : !this.state.formValid}>{this.state.editingIndex >= 0 ? "Update" : "Add"}</Button>
                                    <Button style={{ backgroundColor: '#FDECEB', color: '#DB3737' }} onClick={() => {
                                        this.setState({
                                            addNew: false, editingIndex: -1,
                                            editingSiteType: null,
                                            editingActive: false,
                                        })
                                    }}>Cancel</Button>
                                </div>
                            </DialogContent>
                        </Dialog>
                        : ""}
                    <TableContainer component={Paper} style={{ width: '40%', margin: '0 auto' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell className='tableCell'>Name</TableCell>
                                    <TableCell className='tableCell'>Active</TableCell>
                                    <TableCell className='tableCell'>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.siteTypes.map((siteType, index) => (
                                    <TableRow key={index}>
                                        <TableCell className='tableCell'>
                                            {siteType.name}
                                        </TableCell>
                                        <TableCell className='tableCell'>
                                            {siteType.active ? 'Yes' : 'No'}
                                        </TableCell>
                                        <TableCell className='tableCell'>
                                            <div>
                                                <Button className='viewButtons' onClick={() => this.editSiteType(index, siteType)} style={{ backgroundColor: '#d4d4d4', color: 'black' }}>Edit</Button>
                                                <Button className='viewButtons' onClick={() => this.setState({ deleteId: siteType.id, confirmOpen: true })}>
                                                    Delete
                                                </Button>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </ThemeProvider>
            </>
        );
    };
};

export default withStyles(useStyles)(SiteTypes);
