import React, { Component } from 'react';
import {
    Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Paper, TextField, Checkbox, FormControlLabel,
    Dialog, DialogActions, DialogContent, DialogTitle
} from '@mui/material';
import '../CSS/Settings.css';
import { deleteRecord, getRigTypes, createNewRigType, updateRigType } from '../utils/OpenCampground_Database_Calls';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { withStyles } from '@material-ui/core/styles';

const useStyles = theme => ({
    dialogPaper: {
        padding: theme.spacing(2),
        borderRadius: '10px',
    }
});

const theme = createTheme({
    palette: {
        primary: {
            main: '#2dcd7a',
        },
    },
});

class RigTypes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rigTypes: [],
            rigTypesUpdate: false,
            addNew: false,
            rigType: '',
            formValid: false,
            editingIndex: -1,
            editingRigType: null,
            editingActive: false,
            open: false,
            popupTitle: '',
            popupText: '',
            deleteId: '',
            confirmOpen: false,
            confirmPopupTitle: 'Delete Rig Type',
            ConfirmPopupText: 'Please make sure that the rig type that you are deleting is not associated with any of the current or future reservations.'
        };
    }

    async componentDidMount() {
        this.rigTypes();
    }

    handleChange = (event) => {
        this.setState({
            editingRigType: { ...this.state.editingRigType, name: event.target.value }
        }, () => {
            this.formValidation();
        });
    }

    formValidation = () => {
        if (this.state.editingRigType.name) {
            this.setState({
                formValid: true
            });
        } else if (this.state.formValid) {
            this.setState({
                formValid: false
            });
        }
    }

    deleteRecord = async (data) => {
        let deleteResponse = await deleteRecord(this.props.user, this.props.serverName, "rigtypes", data);
        this.setState({
            rigTypesUpdate: true,
            open: true,
            popupTitle: 'Delete Rig Type',
            popupText: 'Rig type deleted successfully.'
        });
        this.rigTypes();
    }

    rigTypes = async () => {
        if (this.state.rigTypesUpdate) {
            let rigData = await getRigTypes(this.props.user, this.props.serverName)
            this.setState({
                show: 'rigTypes',
                rigTypes: rigData
            })
        } else {
            if (this.state.rigTypes.length > 0) {
                this.setState({
                    show: 'rigTypes'
                })
            } else {
                let rigData = await getRigTypes(this.props.user, this.props.serverName)
                this.setState({
                    show: 'rigTypes',
                    rigTypes: rigData
                })
            }
        }
    }

    addNew = async () => {
        let rigExists = this.state.rigTypes.some(rig => rig.name.trim().toLowerCase() === this.state.editingRigType.name.trim().toLowerCase());
        if (rigExists) {
            this.setState({
                open: true,
                popupText: 'This site type name already exists. Please choose a different name.'
            })
            return;
        }
        let createNewRigTypeResponse = await createNewRigType(this.props.user, this.props.serverName, this.state.editingRigType.name);
        this.setState({
            rigTypesUpdate: true,
            addNew: false,
            editingRigType: null,
            popupTitle: 'New Rig Type',
            popupText: 'New rig type added successfully.',
            open: true
        });
        this.rigTypes();
    }

    editRigType = (index, rigType) => {
        this.setState({
            editingIndex: index,
            editingRigType: rigType,
            editingActive: rigType.active === 1,
        });
    }

    handleEditChange = (event) => {
        this.setState({
            editingRigType: { ...this.state.editingRigType, name: event.target.value }
        });
    }

    handleEditActiveChange = (event) => {
        this.setState({
            editingActive: event.target.checked
        });
    }

    saveEdit = async () => {
        await updateRigType(this.props.user, this.props.serverName, this.state.editingRigType, this.state.editingActive ? 1 : 0, this.state.editingRigType.id);
        this.setState({
            editingIndex: -1,
            rigTypesUpdate: true,
            open: true,
            popupTitle: 'Edit Rig Type',
            popupText: 'Rig type updated successfully.'
        });
        this.rigTypes();
    }

    handleCancel = () => {
        this.setState({ open: false });
    };

    render() {
        const { classes } = this.props;
        return (
            <>
                <ThemeProvider theme={theme}>
                    <Dialog open={this.state.confirmOpen} onClick={() => this.setState({ confirmOpen: false })} classes={{ paper: classes.dialogPaper }}>
                        <DialogTitle>{this.state.confirmPopupTitle}</DialogTitle>
                        <DialogContent>
                            {this.state.ConfirmPopupText}
                        </DialogContent>
                        <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Button onClick={() => this.setState({ confirmOpen: false })} style={{ backgroundColor: '#FDECEB', color: '#DB3737', padding: '2% 10% 2% 10%', margin: '0 0 0 2%', borderRadius: '10px', marginTop: '3%' }}>
                                Cancel
                            </Button>
                            <Button onClick={() => this.deleteRecord(this.state.deleteId)} style={{ backgroundColor: '#2dcd7a', color: '#000000', padding: '2% 10% 2% 10%', margin: '0 0 0 2%', borderRadius: '10px', marginTop: '3%' }}>
                                Confirm
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={this.state.open} onClose={this.handleCancel} classes={{ paper: classes.dialogPaper }}>
                        <DialogTitle>{this.state.popupTitle}</DialogTitle>
                        <DialogContent>
                            {this.state.popupText}
                        </DialogContent>
                        <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Button onClick={this.handleCancel} style={{ backgroundColor: '#FDECEB', color: '#DB3737', padding: '2% 10% 2% 10%', margin: '0 0 0 2%', borderRadius: '10px', marginTop: '3%' }}>
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Button className='resButton' onClick={() => { this.setState({ addNew: true }) }} style={{ backgroundColor: '#2dcd7a', color: '#000000', marginBottom: '2%', textTransform: "capitalize" }}>Add New RigType</Button>
                    {this.state.addNew || this.state.editingIndex >= 0
                        ?
                        <Dialog open={this.state.addNew || this.state.editingIndex >= 0} onClose={() => {
                            this.setState({
                                addNew: false, editingIndex: -1,
                                editRigType: null,
                            });
                        }} maxWidth="sm" fullWidth>
                            <DialogContent dividers>
                                <div>
                                    <h2 style={{ margin: "0px", marginBottom: "26px" }}>{this.state.editingIndex >= 0 ? "Update Rig Type" : "Add Rig Type"}</h2>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '4%' }}>
                                    <TextField id="name" required label="Rig type"
                                        value={this.state?.editingRigType?.name}
                                        onChange={this.handleChange} />
                                    {this.state.editingIndex >= 0 ?
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={this.state.editingActive}
                                                    onChange={this.handleEditActiveChange}
                                                />
                                            }
                                            label="Active"
                                        /> : ""}
                                    <Button style={{ backgroundColor: '#2dcd7a', color: '#000000' }} onClick={this.state.editingIndex >= 0 ? this.saveEdit : this.addNew} disabled={this.state.editingIndex >= 0 ? false : !this.state.formValid}>{this.state.editingIndex >= 0 ? "Update" : "Add"}</Button>
                                    <Button style={{ backgroundColor: '#FDECEB', color: '#DB3737' }} onClick={() => { this.setState({ addNew: false, editingIndex: -1, editingRigType: null, editingActive: false }) }}>Cancel</Button>
                                </div>
                            </DialogContent>
                        </Dialog>
                        : ""}
                    <TableContainer component={Paper} style={{ width: '45%', margin: '0 auto' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell className='tableCell'>Name</TableCell>
                                    <TableCell className='tableCell'>Active</TableCell>
                                    <TableCell className='tableCell'>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.rigTypes.map((rig, index) => (
                                    <TableRow key={index}>
                                        <TableCell className='tableCell'>
                                            {rig.name}
                                        </TableCell>
                                        <TableCell className='tableCell'>
                                            {rig.active ? 'Yes' : 'No'}
                                        </TableCell>
                                        <TableCell className='tableCell'>
                                            <div>
                                                <Button className='viewButtons' onClick={() => this.editRigType(index, rig)} style={{ backgroundColor: '#d4d4d4', color: 'black' }}>Edit</Button>
                                                <Button className='viewButtons' onClick={() => this.setState({ deleteId: rig.id, confirmOpen: true })}>
                                                    Delete
                                                </Button>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </ThemeProvider>
            </>
        );
    };
}

export default withStyles(useStyles)(RigTypes);
