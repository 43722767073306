import React, { Component } from 'react';
import { Grid, Typography, Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
import '../CSS/Users.css';
import { deleteRecord, getUsers, addUser, updateUser } from '../utils/OpenCampground_Database_Calls';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import users from '../assets/manager-users.png';

const theme = createTheme({
    palette: {
        primary: {
            main: '#2dcd7a',
        },
    },
});

class Users extends Component {
    constructor(props) {
        super(props);
        this.state = {
            usersData: [],
            userUpdate: false,
            addNew: false,
            name: '',
            password: '',
            confirmPassword: '',
            admin: false,
            formValid: false,
            editingIndex: null,
            editingAdmin: false,
        };
    }

    async componentDidMount() {
        this.users();
    }

    handleChange = (event) => {
        if (event.target.id === 'admin') {
            this.setState({
                [event.target.id]: event.target.checked
            });
        } else {
            this.setState({
                [event.target.id]: event.target.value
            }, () => {
                this.formValidation();
            });
        }
    }

    formValidation = () => {
        const specialCharacters = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        if (this.state.name && (this.state.password === this.state.confirmPassword) && /\d/.test(this.state.password) &&
            specialCharacters.test(this.state.password) && this.state.password.length > 7 && /[A-Z]/.test(this.state.password)) {
            this.setState({
                formValid: true
            });
        } else if (this.state.formValid) {
            this.setState({
                formValid: false
            });
        }
    }

    deleteRecord = async (data) => {
        await deleteRecord(this.props.user, this.props.serverName, "users", data.id);
        this.setState({
            userUpdate: true
        });
        this.users();
    }

    users = async () => {
        if (this.state.userUpdate) {
            let getUserData = await getUsers(this.props.user, this.props.serverName);
            this.setState({
                usersData: getUserData
            });
        } else {
            if (this.state.usersData.length > 0) {
            } else {
                let getUserData = await getUsers(this.props.user, this.props.serverName);
                this.setState({
                    usersData: getUserData
                });
            }
        }
    }

    addNew = async () => {
        await addUser(this.props.user, this.props.serverName, this.state.name, this.state.password, this.state.admin);
        this.setState({
            userUpdate: true,
            addNew: false
        });
        this.users();
    }

    editUser = (index, user) => {
        this.setState({
            editingIndex: index,
            editingAdmin: user.admin ? true : false,
        });
    }

    handleEditAdminChange = (event) => {
        this.setState({
            editingAdmin: event.target.checked
        });
    }

    saveEdit = async (user) => {
        let updateuserresponse = await updateUser(this.props.user, this.props.serverName, this.state.editingAdmin ? 1 : 0, user.id);
        console.log(updateuserresponse)
        this.setState({
            editingIndex: null,
            userUpdate: true
        });
        this.users();
    }

    render() {
        return (
            <Grid className='settingsPage'>
                <Box className='infoBox'>
                    <Typography className='infoBarTypo'>Welcome: {this.props.serverName.charAt(0).toUpperCase() + this.props.serverName.slice(1)}</Typography>
                    <Typography className='infoBarTypo'>Username: {this.props.user}</Typography>
                </Box>
                <Box className='settingsBox'>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img src={users} alt="Users" className='buttonImage' />
                        <Typography className='settingsTypo'>Users</Typography>
                    </div>
                </Box>
                <Box className='viewBox'>
                    <ThemeProvider theme={theme}>
                        <Button className='resButton' onClick={() => { this.setState({ addNew: true }) }} style={{ backgroundColor: '#2dcd7a', color: '#000000', marginBottom: '2%', marginTop: '7%' }}>Add New User</Button>
                        {this.state.addNew
                            ?
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                                    <TextField
                                        id="name"
                                        required
                                        label="Email Id"
                                        onChange={this.handleChange}
                                    />
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                                    <FormControlLabel
                                        label={"Admin"}
                                        control={
                                            <Checkbox
                                                id="admin"
                                                checked={this.state.admin}
                                                onChange={this.handleChange}
                                            />
                                        }
                                    />
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                                    <TextField
                                        id="password"
                                        required
                                        label="Password"
                                        type='password'
                                        onChange={this.handleChange}
                                    />
                                    <TextField
                                        id="confirmPassword"
                                        required
                                        label="Confirm Password"
                                        type='password'
                                        onChange={this.handleChange}
                                    />
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography style={{ fontSize: '1.3rem' }}>Password Rules:</Typography>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                                    <Typography style={{ fontSize: '1rem' }}>- Password length should be greater than 8</Typography>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                                    <Typography style={{ fontSize: '1rem' }}>- Password should contain at least 1 uppercase letter</Typography>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                                    <Typography style={{ fontSize: '1rem' }}>- Password should contain at least 1 lowercase letter</Typography>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                                    <Typography style={{ fontSize: '1rem' }}>- Password should contain at least 1 number</Typography>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                                    <Typography style={{ fontSize: '1rem' }}>- Password should contain at least 1 special character</Typography>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                                    <Button style={{ backgroundColor: '#2dcd7a', color: '#000000', width: '30%' }} onClick={this.addNew} disabled={!this.state.formValid}>Add</Button>
                                    <Button style={{ backgroundColor: '#FDECEB', color: '#DB3737', width: '30%' }} onClick={() => { this.setState({ addNew: false }) }}>Cancel</Button>
                                </div>
                            </div>
                            :
                            <TableContainer component={Paper} style={{ width: '40%', margin: '0 auto' }}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className='tableCell'>Name</TableCell>
                                            <TableCell className='tableCell'>Admin</TableCell>
                                            <TableCell className='tableCell'>Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.usersData.map((user, index) => (
                                            <TableRow key={index}>
                                                <TableCell className='tableCell'>{user.name}</TableCell>
                                                <TableCell className='tableCell'>
                                                    {this.state.editingIndex === index
                                                        ? <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={this.state.editingAdmin}
                                                                    onChange={this.handleEditAdminChange}
                                                                />
                                                            }
                                                            label="Admin"
                                                        />
                                                        : user.admin ? 'Yes' : 'No'}
                                                </TableCell>
                                                <TableCell className='tableCell'>
                                                    <div>
                                                        {this.state.editingIndex === index
                                                            ? <>
                                                                <Button className='viewButtons' onClick={() => this.saveEdit(user)} style={{ backgroundColor: '#2dcd7a', color: '#000000' }}>Save</Button>
                                                                <Button className='viewButtons' onClick={() => this.setState({ editingIndex: null })} style={{ marginTop: '10%' }}>Cancel</Button>
                                                            </>
                                                            : <>
                                                                <Button className='viewButtons' onClick={() => this.editUser(index, user)} style={{ backgroundColor: '#d4d4d4', color: 'black' }}>Edit</Button>
                                                                <Button className='viewButtons' onClick={() => this.deleteRecord(user)}>Delete</Button>
                                                            </>
                                                        }
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        }
                    </ThemeProvider>
                </Box>
            </Grid>
        );
    }
}

export default Users;
